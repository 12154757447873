<template>
    <section class="new-quote">
        <div id="customer_loader" style="text-align: center; margin-top: 120px; display: none">
            <img src="/images/exp_loader.gif" alt="" style="height: 80px; width: 80px" />
        </div>

        <div class="container" id="main-section">
            <div class="row">
                <!-- Steps Start -->
                <div class="col-md-12 text-center">
                    <div class="steps">
                        <div class="numb one">
                            <h6 class="button-one" data-status="1">1</h6>
                            <span id="price-quote-text">Price Quote</span>
                        </div>

                        <div class="numb two">
                            <h6 class="button-two" data-status="1">2</h6>
                            <span>Select Plan</span>
                        </div>
                        <div class="numb three">
                            <h6 class="button-three" data-status="1">3</h6>
                            <span>Enter Info</span>
                        </div>
                        <div class="numb four">
                            <h6 class="button-four" data-status="1">4</h6>
                            <span>Done</span>
                        </div>
                    </div>
                    <div class="alert alert-success message" v-if="saveQuoteAlert" role="alert"
                        style="text-align: center">
                        {{ saveQuoteAlert }}
                    </div>
                </div>
                <!-- Steps End -->
                <!-- Step One Start -->
                <div class="step-1">
                    <div class="col-md-12 mb-40">
                        <AccountLookup />
                        <h3>Price Quote</h3>
                        <div class="p-q">
                            <form id="setp-1-form">
                                <div class="row">
                                    <div class="form-group col-md-6">
                                        <label>Product</label>
                                        <select class="form-control" @change="getAndSetUtilityProduct($event)"
                                            id="product" ref="product">
                                            <option v-for="item in productData" :value="item.id" :key="item.id">
                                                {{ item.Product }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label>State</label>
                                        <select class="form-control" id="state" @change="getAndSetUtilityState($event)">
                                            <option selected value="0" :data-text="0">All</option>
                                            <option v-for="item in stateData" :value="item.id" :key="item.id"
                                                :data-text="item.StateCode">
                                                {{ item.State }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-md-6">
                                        <label>Utility</label>
                                        <select class="form-control" @change="getAndSetZone($event)" id="utility"
                                            ref="utility">
                                            <option value="0" selected>Select Utility</option>
                                            <option v-for="item in utilityFilterData" :value="item.UtilityId"
                                                :key="item.UtilityId" :data-text="item.UtilityName"
                                                :data-salesforceId="item.SalesForceId" :data-stateCode="item.StateCode">
                                                {{ item.DisplayName }}
                                            </option>
                                        </select>
                                        <span class="errorInput" style="color: red" v-for="error in errors"
                                            :key="error.utility">
                                            {{ error.utility }}
                                        </span>
                                    </div>

                                    <div v-if="this.isIosDevice" class="form-group col-md-6">
                                        <label> Start Date</label>
                                        <div className="iOS-dropdowns-wrap">
                                            <select class="form-control ios-dropdown" id="startMonth" ref="startMonth">
                                                <option v-for="month in this.months"
                                                    :selected="this.defaultMonth == month.id" :value="month.id"
                                                    :key="month.id">
                                                    {{ month.monthName }}
                                                </option>
                                            </select>
                                            <select class="form-control ios-dropdown" id="startYear" ref="startYear">
                                                <option v-for="year in this.yearOptions"
                                                    :selected="this.defaultYear == year" :value="year.id"
                                                    :key="year.id">
                                                    {{ year }}
                                                </option>
                                            </select>
                                        </div>
                                        <span class="errorInput" style="color: red" v-for="error in errors"
                                            :key="error.startDate">
                                            {{ error.startDate }}
                                        </span>
                                    </div>
                                    <div v-else class="form-group col-md-6">
                                        <label>Start Date</label>
                                        <input type="date" :min="getMinDate()" class="form-control" id="startDate"
                                            ref="startDate" :value="getMinShownDate()" onfocus="blur()"
                                            onkeydown="return false" />
                                        <span class="errorInput" style="color: red" v-for="error in errors"
                                            :key="error.startDate">
                                            {{ error.startDate }}
                                        </span>
                                    </div>
                                </div>
                                <div class="row" id="zone-section" style="display: none">
                                    <div class="form-group col-md-6">
                                        <label>Zone Lookup (Zip)</label>
                                        <div class="lookup">
                                            <input type="text" class="form-control" id="ZipCode" name="ZipCode"
                                                ref="ZipCode" />

                                            <span class="errorInput" style="color: red" v-for="error in errors"
                                                :key="error.ZipCode">
                                                {{ error.ZipCode }}
                                            </span>
                                            <a href="javascript:void(0)" @click="lookUpSubmit">Lookup</a>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label>Zone</label>
                                        <select class="form-control" id="zone">
                                            <option selected value="0">Select Zone</option>
                                            <option v-for="item in zoneData" :value="item.id" :key="item.id">
                                                {{ item.Zone }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-md-6" v-if="userData.showCustomerRate === true">
                                        <label id="customer-rate-label">Customer Rate (¢/kwh):</label>
                                        <input type="text" class="form-control number-decimal" placeholder="0.00000"
                                            id="customerRate" ref="customerRate" />
                                        <span class="errorInput" style="color: red" v-for="error in errors"
                                            :key="error.customerRate">
                                            {{ error.customerRate }}
                                        </span>
                                    </div>
                                    <input v-else type="hidden" id="customerRate" />

                                    <div class="form-group col-md-6" v-if="userData.showMargin === true">
                                        <label id="margin-rate-label">Margin</label>
                                        <input type="text" class="form-control number-decimal" placeholder="0.00000"
                                            id="margin" ref="marginRate" />
                                        <span class="errorInput" style="color: red" v-for="error in errors"
                                            :key="error.marginRate">
                                            {{ error.marginRate }}
                                        </span>
                                    </div>
                                    <input v-else type="hidden" id="margin" />
                                </div>
                                <div class="row">
                                    <div class="form-group col-md-6" id="demand-section" style="display: none">
                                        <label>Demand</label>
                                        <select class="form-control" id="demand" ref="demand">
                                            <option v-for="item in demandData" :value="item.Value" :key="item.id">
                                                {{ item.Description }}
                                            </option>
                                        </select>
                                    </div>

                                    <div class="form-group col-md-6" style="display: none">
                                        <label>SaleType:</label>
                                        <select class="form-control" id="SaleType">
                                            <option value="1" selected>New</option>
                                            <option value="2">Renewal</option>
                                        </select>
                                    </div>
                                    <input type="hidden" id="SaleType" />
                                </div>
                                <div class="parent-row-rate" v-if="accountData.length > 0">
                                    <div class="row rate added-account" v-for="item in accountData">
                                        <div class="aact">Account {{ item.sno }}</div>
                                        <div class="form-group col-md-6 rate-section" v-if="ProductId == '1'">
                                            <label>Rate Class</label>
                                            <select v-if="rateCodeData.length > 0 && item.sno == '1'"
                                                class="form-control rate-select" id="rate-select" ref="rateClass">
                                                <option value="0">Rate Class</option>

                                                <option v-for="options in rateCodeData" :value="options.RateCodeId"
                                                    :key="options.RateCodeId"
                                                    v-bind:selected="options.RateCodeId === item.ratevalue">
                                                    {{ options.RateCode }}
                                                </option>
                                            </select>

                                            <select v-else-if="rateCodeData.length > 0" class="form-control rate-select"
                                                :id="`rate-${item.sno}`">
                                                <option value="0">Rate Class</option>

                                                <option v-for="options in rateCodeData" :value="options.RateCodeId"
                                                    :key="options.RateCodeId"
                                                    v-bind:selected="options.RateCodeId === item.ratevalue">
                                                    {{ options.RateCode }}
                                                </option>
                                            </select>
                                            <span v-if="rateCodeData.length > 0 && item.sno == '1'" class="errorInput"
                                                style="color: red" v-for="error in errors" :key="error.rateClass">
                                                {{ error.rateClass }}
                                            </span>
                                        </div>
                                        <div class="form-group col-md-6" v-if="item.sno == '1'">
                                            <label class="annual-usage-lebel">Annual Usage</label>
                                            <input type="text" class="form-control number-decimal" placeholder="0"
                                                id="annual-usage" ref="annual-usage" :value="`${item.annualusage}`" />
                                            <span class="errorInput" style="color: red" v-for="error in errors"
                                                :key="error.annual - usage">
                                                {{ error.annualusage }}
                                            </span>
                                        </div>

                                        <div class="form-group col-md-6" v-else>
                                            <label class="annual-usage-lebel">Annual Usage</label>
                                            <input type="text" class="form-control number-decimal" placeholder="0"
                                                :id="`annual-usage-${item.sno}`" :value="`${item.annualusage}`" />
                                        </div>
                                        <div class="form-group col-md-6" v-if="item.sno > '1'">
                                            <button id="account-remove" class="remove-button" type="button"
                                                style="font-size: 10px; padding: 4px 10px;width: fit-content;background-color: rgb(217, 23, 23);margin-left: -2px;margin-top: 56px;">
                                                Remove
                                            </button>
                                        </div>
                                        <input type="hidden" id="account-no" :value="`${lastsno}`" />
                                    </div>
                                </div>
                                <div class="parent-row-rate" v-else>
                                    <div class="row rate">
                                        <div class="aact">Account 1</div>
                                        <div class="form-group col-md-6 rate-section">
                                            <label>Rate Class</label>
                                            <select class="form-control rate-select" id="rate-select" ref="rateClass">
                                                <option value="0" selected>Rate Class</option>
                                            </select>
                                            <span class="errorInput" style="color: red" v-for="error in errors"
                                                :key="error.rateClass">
                                                {{ error.rateClass }}
                                            </span>
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label class="annual-usage-lebel">Annual Usage</label>
                                            <input type="text" class="form-control number-decimal" placeholder="0"
                                                id="annual-usage" ref="annual-usage" />
                                            <span class="errorInput" style="color: red" v-for="error in errors"
                                                :key="error.annual - usage">
                                                {{ error.annualusage }}
                                            </span>
                                        </div>
                                    </div>
                                    <input type="hidden" id="account-no" value="2" />
                                </div>

                                <a href="javascript:void(0)" @click="addAccount" class="add-accnt">+ Add Account</a>
                                <button type="submit" @click="stepOneSubmit($event, 0)">Next</button>
                            </form>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 pdr-20 mb-40" v-if="userData.allowLCELookUp === true">
                            <div class="p-q">
                                <h3>Electric Account Lookup</h3>
                                <form>
                                    <div class="row">
                                        <div class="form-group col-md-12">
                                            <label>Please Select Utility</label>
                                            <select class="form-control">
                                                <option v-for="item in ElectricUtilityData" :value="item.UtilityId"
                                                    :key="item.UtilityId" :data-text="item.UtilityName">
                                                    {{ item.DisplayName }}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="form-group col-md-12">
                                            <label>Customer Number</label>
                                            <input type="text" class="form-control" id="electic-customer-number"
                                                placeholder="" />
                                            <span class="errorInput" style="color: red" v-for="error in errors"
                                                :key="error.ElectricAccountNumber">
                                                {{ error.ElectricAccountNumber }}
                                            </span>
                                        </div>
                                    </div>
                                    <a href="javascript:void(0)" @click="accountLookup($event, 1)" class="ccc">Check
                                        Current Electric Customer</a>
                                </form>
                            </div>
                        </div>

                        <div class="col-md-6 pdl-20 mb-40" v-if="userData.allowLCELookUp === true">
                            <div class="p-q">
                                <h3>Gas Account Lookup</h3>
                                <form>
                                    <div class="row">
                                        <div class="form-group col-md-12">
                                            <label>Please Select Your Gas Utility</label>
                                            <select class="form-control">
                                                <option v-for="item in GasUtilityData" :value="item.UtilityId"
                                                    :key="item.UtilityId" :data-text="item.UtilityName">
                                                    {{ item.DisplayName }}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="form-group col-md-12">
                                            <label>Customer Number</label>
                                            <input type="text" class="form-control" id="gas-customer-number"
                                                placeholder="" />
                                            <span class="errorInput" style="color: red" v-for="error in errors"
                                                :key="error.GasAccountNumber">
                                                {{ error.GasAccountNumber }}
                                            </span>
                                        </div>
                                    </div>
                                    <a href="javascript:void(0)" @click="accountLookup($event, 2)" class="ccc">Check
                                        Current Gas Customer</a>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Step One End -->
                <!-- Step Two Start -->
                <div class="step-2">
                    <div class="col-md-12 mb-40">
                        <button class="save done done-step-one" data-status="1" style="
                  color: rgb(9, 45, 106);
                  font-weight: 700;
                  font-size: 18px;
                  background: rgb(255, 255, 255);
                  border: 1px solid rgb(9, 45, 106);
                  padding: 8px;
                  float: right;
                  display: none;
                " id="back-button-first-step">
                            Back To Price Quote
                        </button>
                        <h3>Select Plan</h3>
                        <div class="p-q">
                            <form>
                                <div class="row">
                                    <div class="form-group col-md-4">
                                        <label>Utility</label>
                                        <input type="text" class="form-control" id="plan-utility" disabled />
                                    </div>
                                    <input type="hidden" class="form-control" id="plan-start-date" disabled />

                                    <div class="form-group col-md-4">
                                        <label>Start Date</label>
                                        <input type="text" class="form-control" id="plan-start-date-formated"
                                            disabled />
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label class="plan-annual-usage-lebel">Annual Usage (kwh)</label>
                                        <input type="text" class="form-control" id="plan-annual-usage" disabled />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-md-4" v-if="userData.showCustomerRate === true">
                                        <label class="plan-customer-rate-label">Customer Rate (kwh)</label>
                                        <input type="text" class="form-control" id="plan-customer-rate"
                                            placeholder="0.00000" disabled />
                                    </div>
                                    <input v-else type="hidden" id="plan-customer-rate" />
                                    <div class="form-group col-md-4" id="plan-margin-section"
                                        v-if="userData.showMargin === true">
                                        <label class="plan-margin-lebel">Margin</label>
                                        <input type="text" class="form-control" id="plan-margin" placeholder="0.00000"
                                            disabled />
                                    </div>
                                    <input v-else type="hidden" id="plan-margin" />

                                    <div class="form-group col-md-4" id="plan-zone-section" style="display: none">
                                        <label>Zone ddd</label>
                                        <input type="text" class="form-control" id="plan-zone" disabled />
                                    </div>
                                </div>
                            </form>
                            <div class="form-group col-md-4" id="plan-zone-section">
                                <load-factor-calculator />
                            </div>

                            <div class="box row">
                                <div class="d-box tooltip-wrapper" v-for="item in bestPlanProviderData">
                                    <div class="bx">
                                        <div :class="getNotesStyling(item, 'small')">{{ getToolTips(item) }}</div>
                                        <h5>Best {{ item.terms }} Months</h5>

                                        <h4>
                                            Per <span
                                                v-if="utilityIdPriceQuoteAdd === '12' || utilityIdPriceQuoteAdd === '73' && ProductId == '2'"
                                                class="box-rate">mcf</span>
                                            <span
                                                v-else-if="utilityIdPriceQuoteAdd !== '12' && utilityIdPriceQuoteAdd !== '73' && ProductId == '2'"
                                                class="box-rate">ccf</span>

                                            <span v-else-if="ProductId == '1'" class="box-rate">kwh</span>

                                            {{
                                                (parseFloat(item.price) + parseFloat(marginRate))
                                                    .toFixed(5)
                                                    .replace(/^0+/, "")
                                            }}
                                        </h4>

                                        <img :src="backendURL + '/' + item.logo" :alt="item.providername"
                                            style="height:70px;width:100%;object-fit: contain;" />

                                        <h6>{{ item.providername }}</h6>
                                        <div v-if="item.sellApproved === true">
                                            <h5 class="sellApproved">Must Sell</h5> <router-link target="_blank"
                                                class="sellApprovedLink"
                                                :to="{ path: '/ApprovedRates/' + item.providername.split(' ')[0] }">Approved
                                                Rate</router-link>
                                        </div>
                                        <div style="padding:0px !important;">
                                            <span :class="getDateClass(item?.import_date)" class="importDate ">Price
                                                Uploaded On: {{ convertDate(item.import_date) }}</span>
                                        </div>
                                        <h3 v-if="customerRate !== '0'">
                                            Est. Savings
                                            <b>
                                                ${{
                                                    Math.floor(oldPrice - parseFloat(item.price) * annualUsageTotal)
                                                }}
                                            </b>
                                        </h3>
                                        <a href="javascript:void(0)"
                                            style="text-decoration: none; margin-top: 0px !important" @click="
                                                stepTwoSubmit(
                                                    $event,
                                                    item.terms,
                                                    (parseFloat(item.price) + parseFloat(marginRate))
                                                        .toFixed(5)
                                                        .replace(/^0+/, ''),
                                                    item.providername,
                                                    item.providerId,
                                                    item.SalesForceId,
                                                    item
                                                )
                                                ">Sign Up</a>
                                    </div>
                                </div>
                                <div class="d-box tooltip-wrapper">
                                    <div class="bx">
                                        <div :class="getNotesStyling(maxTermBestPlanData, 'small')">
                                            {{ getToolTips(maxTermBestPlanData) }}</div>
                                        <h5>Best {{ maxTermBestPlanData.terms }} Months</h5>
                                        <h4>
                                            Per <span
                                                v-if="utilityIdPriceQuoteAdd === '12' || utilityIdPriceQuoteAdd === '73' && ProductId == '2'"
                                                class="box-rate">mcf</span>
                                            <span
                                                v-else-if="utilityIdPriceQuoteAdd !== '12' && utilityIdPriceQuoteAdd !== '73' && ProductId == '2'"
                                                class="box-rate">ccf</span>

                                            <span v-else-if="ProductId == '1'" class="box-rate">kwh</span>
                                            {{
                                                parseFloat(maxTermBestPlanData.price + parseFloat(marginRate))
                                                    .toFixed(5)
                                                    .replace(/^0+/, "")
                                            }}
                                        </h4>

                                        <img :src="backendURL + '/' + maxTermBestPlanData.logo"
                                            :alt="maxTermBestPlanData.providername"
                                            style="height:70px;width:100%;object-fit: contain;" />

                                        <h6>{{ maxTermBestPlanData.providername }}</h6>
                                        <div v-if="maxTermBestPlanData.sellApproved === true">
                                            <h5 class="sellApproved">Must Sell</h5> <router-link
                                                v-if="maxTermBestPlanData.providername" target="_blank"
                                                class="sellApprovedLink"
                                                :to="{ path: '/ApprovedRates/' + maxTermBestPlanData.providername.split(' ')[0] }">Approved
                                                Rate</router-link>
                                        </div>
                                        <div style="padding:0px !important;">
                                            <span :class="getDateClass(maxTermBestPlanData?.import_date)"
                                                class="importDate">Price Uploaded On:
                                                {{ convertDate(maxTermBestPlanData.import_date) }}</span>
                                        </div>
                                        <h3 v-if="customerRate !== '0'">
                                            Est. Savings
                                            <b>
                                                ${{
                                                    Math.floor(
                                                        oldPrice -
                                                        parseFloat(maxTermBestPlanData.price) * annualUsageTotal
                                                    )
                                                }}
                                            </b>
                                        </h3>
                                        <a href="javascript:void(0)"
                                            style="text-decoration: none; margin-top: 0px !important" @click="
                                                stepTwoSubmit(
                                                    $event,
                                                    maxTermBestPlanData.terms,
                                                    parseFloat(maxTermBestPlanData.price + parseFloat(marginRate))
                                                        .toFixed(5)
                                                        .replace(/^0+/, ''),
                                                    maxTermBestPlanData.providername,
                                                    maxTermBestPlanData.providerId,
                                                    maxTermBestPlanData.SalesForceId,
                                                    maxTermBestPlanData
                                                )
                                                ">Sign Up</a>
                                    </div>
                                </div>
                            </div>
                            <div v-if="showTouMessage">
                                <hr />
                                <p class="tou-message">Chariot Energy <span class="tou-span">Time of Use</span> pricing may be available for this customer.  Click <a href="https://integenergy.sharepoint.com/:x:/g/EQVrsUGOSGNAltALWGxubwEBHEZJ8HOn3rzTWT_KjDISEg?download=1">HERE</a> to download the Time of Use Matrix.</p>
                                <p><span class="tou-span">NOTE: </span> After downloading ensure the TOU matrix is for today by checking the "Presentation Sheet" for the Pricing Date</p>
                            </div>
                            <hr />

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="sorting">
                                        <div class="left">
                                            <select @change="sortingSearch($event)" id="sorting-filter">
                                                <option value="0" selected>Sort By</option>
                                                <option value="1">Term</option>
                                                <option value="2">Rate</option>
                                                <option value="3">Supplier</option>
                                            </select>
                                            <a href="javascript:void(0)" @click="filterSearchTerm('0', '0', 'term-0')"
                                                class="sorting-term" id="term-0"
                                                style="background: #02954f; color: white; text-decoration: none">Show
                                                All Term Lengths</a>
                                            <a href="javascript:void(0)" style="text-decoration: none"
                                                @click="filterSearchTerm('6', '11', 'term-1')" class="sorting-term"
                                                id="term-1">1-11 mo</a>
                                            <a href="javascript:void(0)" style="text-decoration: none"
                                                @click="filterSearchTerm('12', '24', 'term-2')" class="sorting-term"
                                                id="term-2">12-24 mo</a>
                                            <a href="javascript:void(0)" style="text-decoration: none"
                                                @click="filterSearchTerm('36', '60', 'term-3')" class="sorting-term"
                                                id="term-3">36-60 mo</a>
                                        </div>
                                        <div class="right">
                                            <select @change="filterSearchProvider($event)" style="width: 251px"
                                                id="filterSupplier">
                                                <option value="0" selected>All Suppliers</option>
                                                <option v-for="item in uniqueProviderData" :value="item.providerId"
                                                    :key="item.providerId">
                                                    {{ item.providername }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-8">
                                    <div class="tbl">
                                        <table>
                                            <tr v-for="item in searchData">
                                                <div className="tooltip-wrapper">
                                                    <div :class="getNotesStyling(item, '')">{{ getToolTips(item) }}
                                                    </div>

                                                    <td>
                                                        <img :src="backendURL + '/' + item.logo"
                                                            :alt="item.providername" />
                                                    </td>

                                                    <td>
                                                        Rate(<span
                                                            v-if="utilityIdPriceQuoteAdd === '12' || utilityIdPriceQuoteAdd === '73' && ProductId == '2'"
                                                            class="box-rate">mcf</span>
                                                        <span
                                                            v-else-if="utilityIdPriceQuoteAdd !== '12' && utilityIdPriceQuoteAdd !== '73' && ProductId == '2'"
                                                            class="box-rate">ccf</span>

                                                        <span v-else-if="ProductId == '1'"
                                                            class="box-rate">kwh</span>)<b>
                                                            {{
                                                                (parseFloat(item.price) + parseFloat(marginRate))
                                                                    .toFixed(5)
                                                                    .replace(/^0+/, "")
                                                            }}
                                                        </b>
                                                        <div v-if="item.sellApproved === true">
                                                            <span class="sellApproved">Must Sell</span> <router-link
                                                                target="_blank" class="sellApprovedLink"
                                                                :to="{ path: '/ApprovedRates/' + item.providername.split(' ')[0] }">Aproved
                                                                Rate</router-link>
                                                        </div>
                                                    </td>
                                                    <td style="width:30%;">
                                                        Terms <b>{{ item.terms }} months</b> <br />
                                                        <span :class="getDateClass(item?.import_date)"
                                                            class="importDate ">Price Uploaded On:
                                                            {{ convertDate(item.import_date) }}</span>
                                                    </td>
                                                    <td v-if="customerRate !== '0'">
                                                        Est. Savings
                                                        <b>
                                                            ${{
                                                                Math.floor(
                                                                    oldPrice - parseFloat(item.price) * annualUsageTotal
                                                                )
                                                            }}
                                                        </b>
                                                    </td>
                                                    <td>
                                                        <a href="javascript:void(0)" style="text-decoration: none"
                                                            @click="
                                                                stepTwoSubmit(
                                                                    $event,
                                                                    item.terms,
                                                                    (parseFloat(item.price) + parseFloat(marginRate))
                                                                        .toFixed(5)
                                                                        .replace(/^0+/, ''),
                                                                    item.providername,
                                                                    item.providerId,
                                                                    item.SalesForceId,
                                                                    item
                                                                )
                                                                ">Sign Up
                                                        </a>
                                                    </td>
                                                </div>
                                            </tr>
                                        </table>
                                    </div>
                                </div>

                                <div class="col-md-4 notes-column">
                                    <p v-for="item in providerNotesData">
                                        <span>{{ item.providername }}: </span>
                                    <div v-html="item.notes"></div>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Step Two End -->
                <!-- Step Three Start -->
                <div class="step-3">
                    <div class="col-md-12 mb-40">
                        <button class="save done done-step-two" data-status="1" style="
                            color: rgb(9, 45, 106);
                            font-weight: 700;
                            font-size: 18px;
                            background: rgb(255, 255, 255);
                            border: 1px solid rgb(9, 45, 106);
                            padding: 8px;
                            float: right;
                            display: none;" id="back-button-second-step">
                            Back To Select Plan
                        </button>
                        <h3>Enter Info</h3>
                        <div class="p-q">
                            <form id="setp-3-form">
                                <div class="row mb-40">
                                    <div class="col-md-8">
                                        <h1 class="main-head">Enter Information <span
                                                style="color:red;margin-left:10px">(All Fields Required)</span></h1>
                                    </div>
                                    <div class="form-group col-md-12">
                                        <label>Business Name (as shown on bill)</label>
                                        <input type="text" class="form-control" placeholder="Enter Business Name"
                                            id="CompanyName" ref="CompanyName" />
                                        <span class="errorInput" style="color: red" v-for="error in errors"
                                            :key="error.CompanyName">
                                            {{ error.CompanyName }}
                                        </span>
                                    </div>
                                    <div class="form-group col-md-6" style="display: none">
                                        <label class="accountOrEsid">Account Number</label>
                                        <input type="number" class="form-control" placeholder="Enter Account Number"
                                            id="AccountNumber" ref="AccountNumber" />

                                        <span class="errorInput" style="color: red" v-for="error in errors"
                                            :key="error.AccountNumber">
                                            {{ error.AccountNumber }}
                                        </span>
                                    </div>
                                </div>
                                <div class="row mb-40">
                                    <div class="col-md-12">
                                        <h1 class="main-head">Customer Information</h1>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label>First Name</label>
                                        <input type="text" class="form-control field-input"
                                            placeholder="Enter First Name" id="ContactFirstName"
                                            ref="ContactFirstName" />
                                        <span class="errorInput" style="color: red" v-for="error in errors"
                                            :key="error.ContactFirstName">
                                            {{ error.ContactFirstName }}
                                        </span>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label>Last Name</label>
                                        <input type="text" class="form-control field-input"
                                            placeholder="Enter Last Name" id="ContactLastName" ref="ContactLastName" />
                                        <span class="errorInput" style="color: red" v-for="error in errors"
                                            :key="error.ContactLastName">
                                            {{ error.ContactLastName }}
                                        </span>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label>Job Title</label>
                                        <input type="text" class="form-control field-input"
                                            placeholder="Enter Your Job Title" id="JobTitle" ref="JobTitle" />
                                        <span class="errorInput" style="color: red" v-for="error in errors"
                                            :key="error.JobTitle">
                                            {{ error.JobTitle }}
                                        </span>
                                    </div>
                                    <input type="hidden" id="hidden-Term" />
                                    <input type="hidden" id="hidden-Rate" />
                                    <input type="hidden" id="hidden-Provider" />
                                    <input type="hidden" id="hidden-ProviderId" />
                                    <input type="hidden" id="hidden-ProviderSalesForceId" />

                                    <div class="form-group col-md-6">
                                        <label>Phone</label>
                                        <input type="text" class="form-control field-input"
                                            placeholder="Enter Your Phone" id="Phone" ref="Phone" />
                                        <span class="errorInput" style="color: red" v-for="error in errors"
                                            :key="error.Phone">
                                            {{ error.Phone }}
                                        </span>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label>Email</label>
                                        <input type="text" class="form-control field-input"
                                            placeholder="Enter Your Email" id="Email" ref="Email" />
                                        <span class="errorInput" style="color: red" v-for="error in errors"
                                            :key="error.Email">
                                            {{ error.Email }}
                                        </span>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label>Confirm Email</label>
                                        <input type="text" class="form-control field-input"
                                            placeholder="Confirm Your Email" id="cnfEmail" ref="cnfEmail" />
                                        <span class="errorInput" style="color: red" v-for="error in errors"
                                            :key="error.cnfEmail">
                                            {{ error.cnfEmail }}
                                        </span>
                                    </div>
                                </div>
                                <div class="row mb-40">
                                    <div class="col-md-12">
                                        <h1 class="main-head">Billing Information</h1>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label>Street</label>
                                        <input type="text" class="form-control field-input"
                                            placeholder="Enter Your Street Address" id="Street" ref="Street" />
                                        <span class="errorInput" style="color: red" v-for="error in errors"
                                            :key="error.Street">
                                            {{ error.Street }}
                                        </span>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label>City</label>
                                        <input type="text" class="form-control field-input" placeholder="Enter City"
                                            id="City" ref="City" />
                                        <span class="errorInput" style="color: red" v-for="error in errors"
                                            :key="error.City">
                                            {{ error.City }}
                                        </span>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label>State</label>
                                        <select class="form-control" id="info-StateId" ref="info-StateId"
                                            @change="getStateAccount($event)">
                                            <option v-for="item in allStateData" :value="item.id" :key="item.id"
                                                :data-text="item.State">
                                                {{ item.StateCode }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label>Zip</label>
                                        <input type="number" class="form-control field-input"
                                            placeholder="Enter Your Zip" id="Zip" ref="Zip" />
                                        <span class="errorInput" style="color: red" v-for="error in errors"
                                            :key="error.Zip">
                                            {{ error.Zip }}
                                        </span>
                                    </div>
                                </div>
                                <div class="row mb-40 rate-group">
                                    <div class="col-md-12">
                                        <h1 class="main-head">Rate Information</h1>
                                        <p>Please enter either your desired Contract Rate <span
                                                class="bold large">-or-</span> Margin.</p>
                                    </div>
                                    <div v-if="selectedItem.sellApproved" class="col-md-4"><router-link target="_blank"
                                            class="sellApprovedLink"
                                            :to="{ path: '/ApprovedRates/' + selectedItem.providername.split(' ')[0] }">See
                                            Supplier Aproved Rates</router-link></div>
                                    <div class="col-md-6">
                                        <label>Contract Rate</label>
                                        <input type="text" class="form-control" placeholder=".0000" id="visible-rate"
                                            @change="updateRate()" />
                                        <span class="errorInput" style="color: red" v-for="error in errors"
                                            :key="error.ContractRate">
                                            {{ error.ContractRate }}
                                        </span>
                                        <br />
                                        <label>Margin</label>
                                        <input type="text" class="form-control" placeholder=".0000"
                                            id="contract-transfer-margin" @change="updateMargin()">
                                        <label>Transfer Rate</label>
                                        <input type="text" class="form-control" placeholder=".0000" id="transfer-rate"
                                            disabled />
                                    </div>
                                </div>
                                <div class="row mb-40" hidden>
                                    <div class="col-md-12">
                                        <h1 class="main-head">Notes</h1>
                                    </div>
                                    <div class="form-group col-md-12">
                                        <input type="text" class="form-control field-input"
                                            placeholder="Description text here" id="Notes" maxlength="200" />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <h1 class="main-head">Service Address(es) & Account Number(s)</h1>
                                    </div>

                                    <div style="display:none;" class="alert alert-warning message new-message"
                                        v-if="serviceAddressAlert" role="alert">
                                        {{ serviceAddressAlert }}
                                        <button class="save" style="
                                            color: #092d6a;
                                            font-weight: 700;
                                            font-size: 18px;
                                            background: #fff;
                                            border: 1px solid #092d6a;"
                                            @click="stepOneSubmit($event, totalAnualUsageService)">
                                            Refresh Pricing
                                        </button>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="tbl-2">
                                            <table id="serviceAddressTable">
                                                <tr>
                                                    <th>Number</th>
                                                    <th v-if="ProductId == '1'">Rate Class</th>
                                                    <th v-if="accountOrEsid == '1'">Account Number</th>
                                                    <th v-else>ESID</th>
                                                    <th>Address</th>
                                                    <th>City/State/Zip</th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                </tr>
                                                <tr v-for="item in allAccountRateClassAnnualUsageData">
                                                    <td>{{ item.sno }}</td>
                                                    <td v-if="ProductId == '1'">{{ item.ratetext }}</td>
                                                    <!--<td>{{ item.annualusage }}</td>-->
                                                    <td :id="`service-account-${item.sno}`">{{ item.esid }}</td>
                                                    <td :id="`service-address-${item.sno}`">{{ item.address }}</td>
                                                    <td :id="`service-citystatezip-${item.sno}`"
                                                        v-if="item.city !== ''">
                                                        {{ item.city }}, {{ item.statetext }} {{ item.zip }}
                                                    </td>
                                                    <td :id="`service-citystatezip-${item.sno}`" v-else></td>
                                                    <td>
                                                        <a href="javascript:void(0)" @click="showModal(item.sno)"
                                                            class="add-accnt">Edit Service Account</a>
                                                    </td>

                                                    <td v-if="item.billcopyimage === ''">
                                                        <a href="javascript:void(0)" @click="billCopySubmit(item.sno)"
                                                            class="add-accnt">Add Bill Copy</a>
                                                    </td>

                                                    <td v-else>
                                                        <div>
                                                            <img :src="item.billcopyimage" style="width: 47px"
                                                                alt="bllcopyimage" />
                                                            <a href="javascript:void(0)"
                                                                @click="billCopySubmit(item.sno)" class="add-accnt">Edit
                                                                Bill Copy</a>
                                                        </div>
                                                    </td>

                                                    <td v-if="item.sno > 1">
                                                        <a href="javascript:void(0)"
                                                            @click="removeServiceConfirmModel(item.sno)"
                                                            class="add-accnt" title="remove service account"><img
                                                                src="/images/remove.png"
                                                                style="width: 18px;height: auto;"></a>
                                                    </td>
                                                    <td v-else>
                                                        <a class="edit-accnt" @click="showModal(item.sno)"><img
                                                                src="/images/edit.png"
                                                                style="width: 18px;height: auto;"></a>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="col-md-12" style="position:relative;top:20px;">
                                        <a href="javascript:void(0)" @click="addShowModal()" data-bs-toggle="modal"
                                            data-bs-target="#add-servc" class="add-accnt">+ Add Additional Service
                                            Account</a>
                                    </div>
                                    <h1 class="main-head final-check ">
                                        Please review all form fields prior to submitting!
                                    </h1>
                                    <div class="col-md-12">
                                        <div class="conf">
                                            <button class="save" @click="stepThreeSubmit($event, 0)">
                                                Save Quote
                                            </button>
                                            <button class="save" style="
                                                color: #092d6a;
                                                font-weight: 700;
                                                font-size: 18px;
                                                background: #fff;
                                                border: 1px solid #092d6a;
                                                display: none;" id="referesh-pricing"
                                                @click="stepOneSubmit($event, totalAnualUsageService)">
                                                Refresh Pricing
                                            </button>
                                            <button class="s-cont" @click="submitOpportunity($event, 1)">
                                                Generate Contract
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <!-- Step three End -->
                <!-- Step four Start -->
                <div class="final-step">
                    <div class="row">
                        <div class="col-md-6 col-6">
                            <h3>Done</h3>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-md-12 text-center">
                            <div class="p-q">
                                <h1 v-if="!contractGenerationAlert" id="docGenStatusMessage" class="thnks">Thank you.
                                    Your contract has been downloaded.</h1>

                                <div class="alert alert-danger" v-if="contractGenerationAlert" role="alert">
                                    {{ contractGenerationAlert }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <button class="new-quote new-quote-button" @click="stepOneReset">New Quote</button>
                </div>
                <!-- Step four End -->
            </div>
        </div>

    </section>

    <!-- Edit Modal Start -->
    <div class="modal fade common-pop" id="AccountRateClassAnnualUsagemodel" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="alert alert-success message" v-if="successMessageAcocount" role="alert">
                    {{ successMessageAcocount }}
                </div>
                <div class="modal-header">
                    <h2>Add Service Account Information</h2>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                        @click="closeModal"></button>
                </div>
                <div class="modal-body">
                    <div class="p-q">
                        <form>
                            <div class="row">
                                <div class="form-group col-md-12 rate-section">
                                    <label>RATE CLASS</label>
                                    <select class="form-control" id="popupRateClass" ref="popupRateClass"></select>
                                    <span class="errorInput" style="color: red" v-for="error in errors"
                                        :key="error.popupRateClass">
                                        {{ error.popupRateClass }}
                                    </span>
                                </div>
                            </div>
                            <input type="hidden" id="hidden-index" ref="hidden-index" />
                            <div class="row">
                                <div class="form-group col-md-6" hidden>
                                    <label>USAGE</label>
                                    <input type="text" class="form-control changePassword number-decimal"
                                        id="popupUsage" ref="popupUsage" value="0" />
                                    <span class="errorInput" style="color: red" v-for="error in errors"
                                        :key="error.popupUsage">
                                        {{ error.popupUsage }}
                                    </span>
                                </div>
                                <div class="form-group col-md-6">
                                    <label class="editAccountOrEsid">Account Number</label>
                                    <input type="number" class="form-control changePassword" id="popupEsid"
                                        ref="popupEsid" />
                                    <span class="errorInput AccountOrEsidServiceAddress" style="color: red"
                                        v-for="error in errors" :key="error.popupEsid">
                                        {{ error.popupEsid }}
                                    </span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-check col-md-12">
                                    <input type="checkbox" id="editSameBilling" @change="editSameBilling()"><label
                                        style="margin-left:20px;">Same as Billing Address</label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label>ADDRESS</label>
                                    <input type="text" class="form-control changePassword" id="popupAddress"
                                        ref="popupAddress" />
                                    <span class="errorInput" style="color: red" v-for="error in errors"
                                        :key="error.popupAddress">
                                        {{ error.popupAddress }}
                                    </span>
                                </div>
                                <div class="form-group col-md-6">
                                    <label>CITY</label>
                                    <input type="text" class="form-control changePassword" id="popupCity"
                                        ref="popupCity" />
                                    <span class="errorInput" style="color: red" v-for="error in errors"
                                        :key="error.popupCity">
                                        {{ error.popupCity }}
                                    </span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label>STATE</label>
                                    <select class="form-control" id="popupState" ref="popupState"
                                        @change="getEditStateAccountInfo($event)"></select>
                                    <span class="errorInput" style="color: red" v-for="error in errors"
                                        :key="error.popupState">
                                        {{ error.popupState }}
                                    </span>
                                </div>
                                <div class="form-group col-md-6">
                                    <label>ZIP</label>
                                    <input type="number" class="form-control" id="popupZip" ref="popupZip" />
                                    <span class="errorInput" style="color: red" v-for="error in errors"
                                        :key="error.popupZip">
                                        {{ error.popupZip }}
                                    </span>
                                </div>
                            </div>

                            <div class="row">
                                <div class="form-group col-md-12">
                                    <div class="c-btn">
                                        <button class="delt" type="button" @click="closeModal">Cancel</button>
                                        <button class="cncl" @click="editAccountSubmit">Update</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Edit modal End -->
    <!-- Add Modal Start -->
    <div class="modal fade common-pop" id="AddAccountRateClassAnnualUsagemodel" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="alert alert-success message" v-if="successMessageAcocountAdd" role="alert">
                    {{ successMessageAcocountAdd }}
                </div>
                <div class="modal-header">
                    <h2>Add Service Account Information</h2>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                        @click="closeModal"></button>
                </div>
                <div class="modal-body">
                    <div class="p-q">
                        <form id="AcocountAdd-form">
                            <div class="row">
                                <div class="form-group col-md-12 rate-section">
                                    <label>RATE CLASS</label>
                                    <select class="form-control" id="AddpopupRateClass"
                                        ref="AddpopupRateClass"></select>
                                    <span class="errorInput" style="color: red" v-for="error in errors"
                                        :key="error.popupRateClass">
                                        {{ error.popupRateClass }}
                                    </span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-6" hidden>
                                    <label>USAGE</label>
                                    <input type="text" class="form-control changePassword number-decimal"
                                        id="AddpopupUsage" ref="AddpopupUsage" value="0" />
                                    <span class="errorInput" style="color: red" v-for="error in errors"
                                        :key="error.popupUsage">
                                        {{ error.popupUsage }}
                                    </span>
                                </div>
                                <div class="form-group col-md-6">
                                    <label class="addAccountOrEsid">Account Number</label>
                                    <input type="number" class="form-control changePassword" id="AddpopupEsid"
                                        ref="AddpopupEsid" />
                                    <span class="errorInput AccountOrEsidServiceAddress" style="color: red"
                                        v-for="error in errors" :key="error.popupEsid">
                                        {{ error.popupEsid }}
                                    </span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-check col-md-12">
                                    <input type="checkbox" id="addSameBilling" @change="addSameBilling()"><label
                                        style="margin-left:20px;">Same as Billing Address</label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label>ADDRESS</label>
                                    <input type="text" class="form-control changePassword" id="AddpopupAddress"
                                        ref="AddpopupAddress" />
                                    <span class="errorInput" style="color: red" v-for="error in errors"
                                        :key="error.popupAddress">
                                        {{ error.popupAddress }}
                                    </span>
                                </div>
                                <div class="form-group col-md-6">
                                    <label>CITY</label>
                                    <input type="text" class="form-control changePassword" id="AddpopupCity"
                                        ref="AddpopupCity" />
                                    <span class="errorInput" style="color: red" v-for="error in errors"
                                        :key="error.popupCity">
                                        {{ error.popupCity }}
                                    </span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label>STATE</label>
                                    <select class="form-control" id="AddpopupState" ref="AddpopupState"
                                        @change="getAddStateAccountInfo($event)"></select>
                                    <span class="errorInput" style="color: red" v-for="error in errors"
                                        :key="error.popupState">
                                        {{ error.popupState }}
                                    </span>
                                </div>
                                <div class="form-group col-md-6">
                                    <label>ZIP</label>
                                    <input type="number" class="form-control" id="AddpopupZip" ref="AddpopupZip" />
                                    <span class="errorInput" style="color: red" v-for="error in errors"
                                        :key="error.popupZip">
                                        {{ error.popupZip }}
                                    </span>
                                </div>
                            </div>

                            <div class="row">
                                <div class="form-group col-md-12">
                                    <div class="c-btn">
                                        <button class="delt" type="button" @click="closeModal">Cancel</button>
                                        <button class="cncl" @click="addAccountSubmit">Add</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Add modal End -->
    <!-- Add Bill Copy Modal Start -->
    <div class="modal fade common-pop" id="AddBillCopyModel" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document" style="
          max-width: 100%;
          margin: 50px;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          height: 88vh;
          display: flex;
        ">
            <div class="modal-content">
                <div class="alert alert-success message" v-if="successMessageBillCopyAdd" role="alert">
                    {{ successMessageBillCopyAdd }}
                </div>
                <div class="modal-header">
                    <h2>Bill Copy</h2>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                        @click="closeModal"></button>
                </div>
                <div class="modal-body">
                    <div class="p-q">
                        <form enctype="multipart/form-data" id="billCopyForm">
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label>Bill Copy</label>
                                    <input type="file" @change="handleChange" class="form-control" id="file"
                                        accept="image/png, image/gif,image/jpg, image/jpeg" />
                                    <span class="errorInput" id="imageFileError" style="color: red"
                                        v-for="error in errors" :key="error.file">
                                        {{ error.file }}
                                    </span>
                                </div>
                                <input type="hidden" id="BillSnoHidden" ref="BillSnoHidden" />

                                <div class="form-group col-md-6">
                                    <div class="c-btn">
                                        <button class="remove-img" @click="removeBillCopySubmitConfirm"
                                            style="background-color: #d91717; display: none">
                                            Remove
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-12">
                                    <img class="image-box" style="max-width: 100%" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-12">
                                    <div class="c-btn">
                                        <button class="cncl upload-img" @click="formbillCopySubmit">
                                            Upload
                                        </button>
                                        <button class="delt" @click="closeModal">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Add Bill Copy modal End -->
    <!-- Start confirm modal -->
    <div class="modal fade" id="confirmModel" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="btn-close" @click="closeModal" data-bs-dismiss="modal"
                        aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <h4>Are you sure you want to submit this opportunity?</h4>
                    <div class="c-btn">
                        <button class="cncl" @click="closeModal">Cancel</button>
                        <button class="delt" id="submit-contract" @click="submitOpportunity($event, 1)"
                            style="width: 186px">
                            Generate Contract
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- end confirm modal -->
    <!-- update confirmation modal -->
    <div class="modal fade" id="RemoveConfirmation" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog" role="document" style="width: 30%">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="btn-close" @click="closeConf" data-bs-dismiss="modal"
                        aria-label="Close"></button>
                </div>

                <div class="modal-body">
                    <h4>Are you sure, you want to remove this Bill Copy Image?</h4>
                    <div class="c-btn">
                        <button class="cncl" @click="closeConf">Cancel</button>
                        <button class="delt" @click.prevent="removeBillCopySubmit">Confirm</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- end update confirmation modal -->
    <!-- Start delete confirm modal -->
    <div class="modal fade" id="removeconfirmModel" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="alert alert-success message" v-if="successMessageServiceAccount" role="alert">
                    {{ successMessageServiceAccount }}
                </div>
                <div class="modal-header">
                    <button type="button" class="btn-close" @click="closeModal" data-bs-dismiss="modal"
                        aria-label="Close"></button>
                </div>
                <input type="hidden" id="removeSno" />
                <div class="modal-body">
                    <h4>Are you sure you want to remove this Service Account?</h4>
                    <div class="c-btn">
                        <button class="cncl" @click="closeModal">Cancel</button>
                        <button class="delt" @click="removeServiceAccount($event)" style="width: 186px">
                            Remove
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- end delete confirm modal -->
    <!-- Start confirm modal -->
    <div class="modal fade" id="NintextDocGenPackages" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="btn-close" @click="closeAllModal" data-bs-dismiss="modal"
                        aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <h4>Contracts</h4>

                    <div class="p-q">
                        <div class="row">
                            <div class="form-group col-lg-12">
                                <label>Select Contracts</label>
                                <select class="form-control" id="NintexDocGenPackage" name="NintexDocGenPackage">
                                    <option value=" " dataDeliveryOptionId=" " selected>NONE</option>
                                </select>
                            </div>
                            <div class="form-group col-md-12">
                                <div class="c-btn">
                                    <button class="cncl" @click="closeAllModal">Cancel</button>
                                    <button id="submit-contract-salesforce" class="delt"
                                        @click="stepSalesForece($event, 1)" style="width: 186px">
                                        Generate Contract
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Confirm modal -->
    <!-- Existing Accounts Modal start -->
    <div class="modal fade" id="ExistingAccountsModel" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="btn-close" @click="closeAllModal" data-bs-dismiss="modal"
                        aria-label="Close"></button>
                </div>
                <div class="modal-body dupe-modal">
                    <h4 class="dupe-title">Existing Accounts</h4>
                    <div class="p-q">
                        <div v-if="!noSubAgentMessage" class="row">
                            <h5 class="dupe-message">This is an Integrity Customer. Please select from the drop down
                                below your account name</h5>

                            <div class="form-group col-lg-12">
                                <label>Select Accounts</label>
                                <select class="form-control dupe-dropdown" id="ExistingAccounts"
                                    name="ExistingAccounts">
                                    <option v-for="option in existingAccountOptions" :value="option.value" selected>
                                        {{ option.accountName }}
                                    </option>
                                </select>
                            </div>
                            <div class="form-group col-md-12">
                                <div class="c-btn">
                                    <button class="cncl" @click="closeAllModal">Cancel</button>
                                    <button id="submit-contract-salesforce-existing-accounts" class="delt"
                                        @click="stepSalesForece($event, 1)" style="width: 186px">
                                        Generate Contract
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <h5 class="dupe-message">Customer already exists. Please contact your channel manager.
                            </h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Existing Accounts Modal end here -->
    <!-- Existing Contacts Modal start here -->
    <div class="modal fade" id="ExistingContactsModel" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="btn-close" @click="closeAllModal" data-bs-dismiss="modal"
                        aria-label="Close"></button>
                </div>
                <div class="modal-body dupe-modal">
                    <h4 class="dupe-title">Existing Contacts</h4>

                    <div class="p-q">
                        <div class="row">
                            <h5 class="dupe-message">This is an Integrity Customer. Please select from the drop down
                                below your account name</h5>

                            <div class="form-group col-lg-12">
                                <label>Select Contact</label>
                                <select class="form-control dupe-dropdown" id="ExistingAccounts"
                                    name="ExistingAccounts">
                                    <option v-for="option in existingAccountOptions" :value="option.value" selected>
                                        {{ option.accountName }}
                                    </option>
                                </select>
                            </div>
                            <div class="form-group col-md-12">
                                <div class="c-btn">
                                    <button class="cncl" @click="closeAllModal">Cancel</button>
                                    <button id="submit-contract-salesforce-existing-contacts" class="delt"
                                        @click="stepSalesForece($event, 1)" style="width: 186px">
                                        Generate Contract
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Existing Contacts Modal end here -->
    <div class="loading-message" id="LargeContractDisclaimer">
        <p>Contracts with multiple accounts may take up to 10min to generate.</p>
    </div>
</template>


<script>
import axios from "axios";
import constant from "../../constant";
import LoadFactorCalculator from "./LoadFactorCalculator.vue";

import AccountLookup from "../AccountLookup.vue";

export default {
    name: "Pricequote",
    components: {
        LoadFactorCalculator,
        AccountLookup
    },
    props: [
        'edit_customer',
        "Customer_id",
        "UtilityId",
        "CustomerProductId",
    ],
    data() {
        return {
            editCustomer: this.edit_customer,
            editProductId: 2,
            backendURL: constant.backendUrl,
            productData: [],
            stateData: [],
            allStateData: [],
            utilityData: [],
            utilityFilterData: [],
            ElectricUtilityData: [],
            GasUtilityData: [],
            ProductId: 1,
            volumnData: [],
            zoneData: [],
            errors: [],
            demandData: [],
            rateCodeData: [],
            userData: [],
            searchData: [],
            bestPlanProviderData: [],
            providerNotesData: [],
            uniqueProviderData: [],
            searchDataConstant: [],
            filterTermMin: "0",
            filterTermMax: "0",
            filterProvider: "0",
            maxTermBestPlanData: "",
            marginRate: 0,
            oldPrice: 0,
            customerRate: 0,
            customerId: 0,
            allAccountRateClassAnnualUsageData: [],
            accountData: [],
            successMessageAcocount: null,
            successMessageAcocountAdd: null,
            successMessageBillCopyAdd: null,
            accountOrEsid: "1",
            serviceAddressAlert: null,
            saveQuoteAlert: null,
            totalAnualUsageService: 0,
            successMessageServiceAccount: null,
            annualUsageTotal: 0,
            NintexDocGenPackageData: [],
            salesForceToken: "",
            contractLatestPublishedVersionId: "",
            contractTitle: "",
            contractGenerationAlert: null,
            editAccountOrEsid: "1",
            utilityIdPriceQuoteAdd: null,
            utilityAccountValidation: [],
            allowChangeRate: false,
            selectedItem: { sellApproved: null },
            queryResult: {},
            docGenContractData: {},
            opportunityId: null,
            noSubAgentMessage: false,
            editRateInfo: {},
            stateInfo: {},
            months: [
                { id: 0, monthName: "January" },
                { id: 1, monthName: "February" },
                { id: 2, monthName: "March" },
                { id: 3, monthName: "April" },
                { id: 4, monthName: "May" },
                { id: 5, monthName: "June" },
                { id: 6, monthName: "July" },
                { id: 7, monthName: "August" },
                { id: 8, monthName: "September" },
                { id: 9, monthName: "October" },
                { id: 10, monthName: "November" },
                { id: 11, monthName: "December" },
            ],
            existingAccountOptions: [],
            existingContactOptions: [],
            isExistingContact: "0",
            showTouMessage: false,
            showTou: false,
        };
    },
    computed: {
        isIosDevice: function () {
            if ((window.navigator.userAgent.includes("Windows")) || (window.navigator.userAgent.includes("win"))) {
                return false;
            } else {
                return true;
            }

        },
        defaultMonth: function () {
            let newDate = new Date();
            let currentDay = newDate.getDate();
            if (currentDay > 15) {
                return newDate.getMonth() + 2;
            }
            else {
                return newDate.getMonth() + 1;
            }
        },
        yearOptions: function () {
            let currentDate = new Date();
            let yearsArray = [];
            let currentYear = currentDate.getFullYear();
            for (let i = 0; i < 3; i++) {
                let year = currentYear + i;
                yearsArray.push(year);
            }
            return yearsArray;
        },
        defaultYear: function () {
            let newDate = new Date();
            let currentDay = newDate.getDate();
            let currentMonth = newDate.getMonth();
            let currentYear = newDate.getFullYear();

            if (((currentDay > 15) && (currentMonth >= 10)) || ((currentDay < 15) && (currentMonth > 10))) {
                let yearFromNow = new Date(new Date().setFullYear(new Date().getFullYear() + 1)).getFullYear();
                return yearFromNow;
            } else {
                return currentYear;
            }
        }

    },

    created() {
        this.getProducts();
        this.getStates();
        this.getUtilities();

        const salesforceContactId = localStorage.getItem("salesforceContactId");
        this.subagentId = salesforceContactId;

    },
    mounted: function () {

        // Edit Customer Data Start Here
        if (this.CustomerProductId !== undefined) {
            var utilityId = this.UtilityId;
            var productId = this.CustomerProductId;
            this.ProductId = productId;
            this.customerId = this.Customer_id;

            if (productId == 2) {
                $(".rate-section").hide();
                $(".remove-button").css("margin-top", "56px");
                $("#visible-rate").css("display", "block");
                $("#customer-rate-label").text("Customer Rate (¢/CCF)");
                $("#margin-rate-label").text("Margin (per CCF)");
                $(".annual-usage-lebel").text("Annual Usage (in CCF)");
                $(".plan-annual-usage-lebel").text("Annual Usage (ccf)");
                $(".plan-customer-rate-label").text("Margin per (ccf)");
                $(".plan-margin-lebel").text("Customer Rate (ccf)");
                $(".box-rate").text("ccf");
            } else {
                $(".rate-section").show();
                $("#visible-rate").css("display", "block");
                $("#customer-rate-label").text("Customer Rate (¢/kwh)");
                $("#margin-rate-label").text("Margin");
                $(".annual-usage-lebel").text("Annual Usage");
                $(".plan-annual-usage-lebel").text("Annual Usage (kwh)");
                $(".plan-customer-rate-label").text("Customer Rate (kwh)");
                $(".plan-margin-lebel").text("Margin");
                $(".box-rate").text("kwh");
            }
        }

        if (this.edit_customer == 1) {
            this.getCustomerData();
            this.editCustomerGetAndSetZone();
            this.getUtilities();
            this.getProducts();

            $(document).ready(function () {
                $(this).css("background", "#02954f");
                $(this).css("color", "white");

                $(".step-1,.step-2,.step-3").hide();

                $(".step-3").show();
                window.scrollTo(0, 0);
                $(".three").addClass("active");

                $("#price-quote-text").css("color", "#c1c1c1");
                $(".button-one").css("background", "#c1c1c1");
                $(".done,.button-three").css("cursor", "pointer");
                $(".button-one,.button-two,.button-four").css(
                    "cursor",
                    "not-allowed"
                );
                $(".button-three").attr("data-status", "1");
                $(".button-one,.button-two,.button-four").attr("data-status", "0");
            });
        }
        // Edit Customer Data End Here

        this.getUserProfilePromise = this.getUserProfile();

        // Decimal Algorithm Start
        $(document).on("mouseleave", "#customerRate", function () {
            var originalvalue = $(this).val();

            if (originalvalue.length === 0) {
                return false;
            }

            const decimalvalue = $(this).val().split(".")[1];
            const befordecimalvalue = $(this).val().split(".")[0];
            if (decimalvalue !== undefined) {
                if (decimalvalue.length === 0) {
                    $("#customerRate").val(originalvalue + "00000");
                } else if (decimalvalue.length === 1) {
                    $("#customerRate").val(originalvalue + "0000");
                } else if (decimalvalue.length === 2) {
                    $("#customerRate").val(originalvalue + "000");
                } else if (decimalvalue.length === 3) {
                    $("#customerRate").val(originalvalue + "00");
                } else if (decimalvalue.length === 4) {
                    $("#customerRate").val(originalvalue + "0");
                } else if (decimalvalue.length > 5) {
                    const decimaltrimm = decimalvalue.substring(0, 5);
                    $("#customerRate").val(befordecimalvalue + "." + decimaltrimm);
                }
            } else {
                $("#customerRate").val(originalvalue + ".00000");
            }
        });

        $(document).on("mouseleave", "#margin", function () {
            var originalvalue = $(this).val();
            if (originalvalue.length === 0) {
                return false;
            }
            const decimalvalue = $(this).val().split(".")[1];
            const befordecimalvalue = $(this).val().split(".")[0];
            if (decimalvalue !== undefined) {
                if (decimalvalue.length === 0) {
                    $("#margin").val(originalvalue + "00000");
                } else if (decimalvalue.length === 1) {
                    $("#margin").val(originalvalue + "0000");
                } else if (decimalvalue.length === 2) {
                    $("#margin").val(originalvalue + "000");
                } else if (decimalvalue.length === 3) {
                    $("#margin").val(originalvalue + "00");
                } else if (decimalvalue.length === 4) {
                    $("#margin").val(originalvalue + "0");
                } else if (decimalvalue.length > 5) {
                    const decimaltrimm = decimalvalue.substring(0, 5);
                    $("#margin").val(befordecimalvalue + "." + decimaltrimm);
                }
            } else {
                $("#margin").val(originalvalue + ".00000");
            }
        });
        // Decimal Algorithm End

        // Allowing only number & decimal start
        $(document).on("input", ".number-decimal", function (evt) {
            this.value = this.value.replace(/[^0-9\.]+/g, "");
        });
        // Allowing only number & decimal end

        // Start Date on step 1 start here
        $(function () {
            let day = new Date().getDate();
            $(".date-picker").datepicker({
                changeMonth: true,
                changeYear: true,
                showButtonPanel: true,
                dateFormat: "MM yy",
                defaultDate: (day > 15) ? "2m" : "1m",
                minDate: (day > 15) ? "1m" : "0m",
                defaultDate: (day > 15) ? "2m" : "1m",
                minDate: "1m",
                onClose: function (dateText, inst) {
                    $(this).datepicker(
                        "setDate",
                        new Date(inst.selectedYear, inst.selectedMonth, 1)
                    );
                },
            });
        });
        // Start Date on step 1 end here

        $(".one").addClass("active");
        $(".button-one").css("cursor", "pointer");
        $(".button-two,.button-three,.button-four").css("cursor", "not-allowed");
        $(".button-two,.button-three,.button-four").attr("data-status", "0");
        $(".step-2,.step-3,.final-step").hide();

        $(document).on("click", ".done-step-one,.button-one", function () {
            const status = $(this).attr("data-status");
            if (status == "0") {
                return false;
            }
            $(".step-1").show();
            $(".step-2,.step-3,.final-step").hide();
        });

        $(document).on("click", ".done-step-two,.button-two", function () {
            const status = $(this).attr("data-status");
            if (status == "0") {
                return false;
            }
            $(".step-2").show();
            $(".step-1,.step-3,.final-step").hide();
        });

        $(document).on("click", ".done-step-three,.button-three", function () {
            const status = $(this).attr("data-status");
            if (status == "0") {
                return false;
            }
            $(".step-3").show();
            $(".step-1,.step-2,.final-step").hide();
        });


        $(document).on("click", ".button-four", function () {
            const status = $(this).attr("data-status");
            if (status == "0") {
                return false;
            }
            $(".final-step").show();
            $(".step-1,.step-2,.step-3").hide();
        });

        // remove account start here
        $(document).on("click", "#account-remove", function () {
            $(this).closest(".added-account").remove();
            const accountno = $("#account-no").val();
            $("#account-no").val(parseInt(accountno) - parseInt(1));
        });
        // remove account end here
        // Phone number formate start here 
        $(document).on('input', '#Phone', function (event) {
            this.value = this.value.replace(/[^0-9]+/gi, '');
        });
        $(document).on('keyup', '#Phone', function () {
            var number = $(this).val();
            number = number.replace(/(\d{10})\d/, "$1");
            $(this).val(number)
        });
        // Phone number format end here
    },
    methods: {
        contractAxios(retries = 60) {
            axios({
                method: "post",
                url: constant.apiUrl + "/ContractGeneartion",
                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": localStorage.getItem("token"),
                },
                data: {
                    opportunityId: this.opportunityId,
                    customerId: this.userid,
                },
            })
                .then((result) => {
                    console.log("Contract Generation then: " + JSON.stringify(result))
                    if (result.data.success === true) {
                        console.log("Contract Generation Success")
                        $("#customer_loader").hide();
                        $("#main-section").show();

                        this.salesForceToken = result.data.downloadToken;
                        this.contractLatestPublishedVersionId =
                            result.data.data.LatestPublishedVersionId;
                        this.contractTitle = result.data.data.Title;
                        this.downloadContract();
                        $("#LargeContractDisclaimer").css("display", "none");
                    } else if (result.data.success === false) {
                        console.log("could not generate")
                        if (retries > 0) {
                            this.contractAxios(retries - 1);
                        }
                        else {
                            $("#generateContractSection").hide();
                            $("#customer_loader").hide();
                            $("#main-section").show();
                            $("#LargeContractDisclaimer").css("display", "none");
                            this.contractGenerationAlert = result.data.message;
                        }
                    }
                })
                .catch((err) => {
                    $("#customer_loader").hide();
                    $("#main-section").show();
                    $("#generateContractSection").hide();
                    this.contractGenerationAlert = err.message;
                    alert(err.message);
                    console.log(err.message);
                });
        },
        getDateClass(dateTimeString) {
            let today = new Date().toISOString().split("T")[0];

            if (dateTimeString) {
                var dateString = dateTimeString.toString().split("T")[0];
                if (dateString !== today) {
                    return "red";
                } else {
                    return ""
                }
            }

        },
        getMinDate() {
            let minDate = new Date();
            let dayOfMonth = minDate.getDate();
            if (dayOfMonth > 15) {
                minDate.setMonth(minDate.getMonth() + 1);
                minDate.setDate(1);
            }
            minDate = new Date(minDate).toISOString().split("T")[0];
            return minDate;
        },
        getMinShownDate() {
            let minShownDate = new Date();
            let dayOfMonth = minShownDate.getDate();
            if (dayOfMonth < 15) {
                minShownDate.setMonth(minShownDate.getMonth() + 1);
                minShownDate.setDate(1);
            }
            else {
                minShownDate.setMonth(minShownDate.getMonth() + 2);
                minShownDate.setDate(1);
            }
            minShownDate = new Date(minShownDate).toISOString().split("T")[0];
            return minShownDate;
        },
        getToolTips(item) {
            const parser = new DOMParser();
            if (this.providerNotesData) {
                for (let i = 0; i <= this.providerNotesData.length; i++) {
                    if (this.providerNotesData[i]?.providerId === item.providerId) {
                        return parser.parseFromString(this.providerNotesData[i]?.notes, "text/html").documentElement.textContent.trim();
                    }
                }
            }
        },
        getNotesStyling(item, size) {
            let content = this.getToolTips(item);
            if (content) {
                return `tooltiptext${size}`
            } else {
                console.log("else")
                return ""
            }
        },
        convertDate(datetimeString) {
            if (datetimeString == undefined) {
                return "";
            }
            var dateString = datetimeString.toString().split("T")[0];
            var dateStringArray = dateString.toString().split("-");
            var newDateString = dateStringArray[1] + "-" + dateStringArray[2] + "-" + dateStringArray[0];
            return newDateString;
        },
        getCustomerData() {
            axios({
                method: "post",
                url: constant.apiUrl + "/editCustomer",
                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": localStorage.getItem("token"),
                },
                data: {
                    CustomerId: this.Customer_id,
                },
            })
                .then((result) => {
                    if (result.status === 200) {
                        if (result.data.serviceBilCopyData.length > 0) {
                            this.accountData = result.data.serviceBilCopyData;

                            console.log("result.data.serviceBilCopyData: " + JSON.stringify(result.data.serviceBilCopyData));

                            this.allAccountRateClassAnnualUsageData = result.data.serviceBilCopyData;

                            //when edituing customer is array with one object in it

                            const oldData = this.allAccountRateClassAnnualUsageData;
                            var last_element = oldData[oldData.length - 1];
                            const sno = parseInt(last_element.sno) + 1;
                            this.lastsno = sno;
                            //sno = 1 for the most part from what i can see
                            this.totalAnualUsageService = result.data.serviceBilCopyData.reduce(
                                (a, {
                                    annualusage
                                }) => a + annualusage,
                                0
                            );
                        }
                        let transferRate = (result.data.CustomerData.Rate - result.data.CustomerData.Margin).toFixed(5).replace(/^0+/, '');
                        var mydate = new Date(result.data.CustomerData.StartDate);
                        var month = [
                            "January",
                            "February",
                            "March",
                            "April",
                            "May",
                            "June",
                            "July",
                            "August",
                            "September",
                            "October",
                            "November",
                            "December",
                        ][mydate.getMonth()];
                        var startDATE = month + " " + mydate.getFullYear();
                        var cleanedPhone = ('' + result.data.CustomerData.Phone).replace(/\D/g, '');
                        let formattedPhone = cleanedPhone.replace(/(\d{3})(\d{3})(\d{4})/, '($1)$2-$3');

                        $(".date-picker").val(startDATE);
                        $("#AccountNumber").val(result.data.CustomerData.AccountNumber);
                        $("#CompanyName").val(result.data.CustomerData.CompanyName);
                        $("#ContactFirstName").val(result.data.CustomerData.ContactFirstName);
                        $("#ContactLastName").val(result.data.CustomerData.ContactLastName);
                        $("#JobTitle").val(result.data.CustomerData.JobTitle);
                        $("#Phone").val(formattedPhone);
                        $("#Email").val(result.data.CustomerData.Email);
                        $("#cnfEmail").val(result.data.CustomerData.Email);
                        $("#Street").val(result.data.CustomerData.Street);
                        $("#City").val(result.data.CustomerData.City);
                        $("#info-StateId").val(result.data.CustomerData.StateId);
                        $("#Zip").val(result.data.CustomerData.Zip);
                        $("#Notes").val(result.data.CustomerData.Notes);
                        $("#hidden-Term").val(result.data.CustomerData.Term);
                        $("#hidden-Rate").val(result.data.CustomerData.Rate);
                        $("#hidden-Provider").val(result.data.CustomerData.providername);
                        $("#hidden-ProviderId").val(result.data.CustomerData.ProviderId);
                        $("#plan-annual-usage").val(result.data.CustomerData.Quantity);
                        $("#annual-usage").val(result.data.CustomerData.Quantity);
                        $("#plan-start-date").val(result.data.CustomerData.StartDate);
                        $("#plan-utility").val(result.data.CustomerData.DisplayName);
                        $("#demand").val(result.data.CustomerData.Demand);
                        $("#zone").val(result.data.CustomerData.ZoneId);
                        $(".box-rate").first().text(result.data.CustomerData.Unit);
                        $("#margin").val(result.data.CustomerData.Margin);
                        $("#transfer-rate").val(transferRate);
                        $("#utility").val(result.data.CustomerData.UtilityId);
                        $("#product").val(result.data.CustomerData.Product)

                        console.log("Result pending: " + JSON.stringify(result.data))
                        this.utilityIdPriceQuoteAdd = result.data.CustomerData.UtilityId;
                        this.editRateInfo = {
                            rateCodeId: result?.data?.serviceBilCopyData[0]?.ratevalue ? result?.data?.serviceBilCopyData[0]?.ratevalue : 0,
                            rateClass: result.data.serviceBilCopyData[0].ratetext ? result.data.serviceBilCopyData[0].ratetext : ""
                        }
                        this.stateInfo = {
                            stateId: result?.data?.CustomerData.StateId ? result?.data?.CustomerData.StateId : "",
                            stateCode: result?.data?.CustomerData.StateCode ? result?.data?.CustomerData.StateCode : "",
                            stateTextName: result?.data?.serviceBilCopyData.statetext ? result?.data?.serviceBilCopyData.statetext : ""
                        }
                        this.stepOneSubmit(false, result.data.CustomerData.Quantity);
                        $("#back-button-second-step").show();
                    }
                })
                .catch((err) => {
                    console.log("Error", err.message);
                });
        },

        // Lookup Features Start here
        accountLookup(event, id) {
            event.preventDefault();
            this.errors = [];
            if (id === 1) {
                var accountNo = $("#electic-customer-number").val();
                if (accountNo == "") {
                    this.errors.push({
                        ElectricAccountNumber: "Customer Number is required"
                    });
                    return false;
                }

                var commodity = "Electric";
            } else if (id === 2) {
                var accountNo = $("#gas-customer-number").val();
                if (accountNo == "") {
                    this.errors.push({
                        GasAccountNumber: "Customer Number is required"
                    });
                    return false;
                }

                var commodity = "Gas";
            }

            axios({
                method: "post",
                url: constant.apiUrl + "/lookUpAccount",
                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": localStorage.getItem("token"),
                },
                data: {
                    accountNo: accountNo,
                    commodity: commodity,
                },
            })
                .then((result) => {
                    if (result.status === 200) {
                        if (result.data.success === true) {
                            alert("Account found");
                        } else if (result.data.success === false) {
                            alert("Accoount Not found");
                        }
                    } else {
                        alert("somethng went wrong");
                    }
                })
                .catch((err) => {
                    console.log(err.message);
                    alert("somethng went wrong");
                });
        },
        // Lookup Features Start here

        //  Edit Customer Get zone data ,rate class data ,volumn data start here
        editCustomerGetAndSetZone() {
            console.log("Getting and setting zone")
            axios({
                method: "post",
                url: constant.apiUrl + "/UtilityZone",
                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": localStorage.getItem("token"),
                },
                data: {
                    utilityId: this.UtilityId,
                },
            })
                .then((result) => {
                    if (result.status === 200) {
                        if (result.data.utilityZoneData.length > 0) {
                            this.zoneData = result.data.utilityZoneData;
                        } else {
                            this.zoneData = [];
                        }

                        if (result.data.volumnData.length > 0) {
                            this.volumnData = result.data.volumnData.length;

                            $("#customer-rate-label").text("Customer Rate (¢/MCF)");
                            $("#margin-rate-label").text("Margin (per MCF)");
                            $(".annual-usage-lebel").text("Annual Usage (in MCF)");
                        } else {
                            if (this.ProductId == "2") {
                                $("#customer-rate-label").text("Customer Rate (¢/CCF)");
                                $("#margin-rate-label").text("Margin (per CCF)");
                                $(".annual-usage-lebel").text("Annual Usage");
                            }
                        }

                        if (result.data.rateCodeData.length > 0) {
                            this.rateCodeData = result.data.rateCodeData;
                            var selectValues = result.data.rateCodeData;

                            var $mySelect = $(".rate-select");

                            $mySelect.html('<option value="0" selected="">Rate Class</option>');

                            $.each(selectValues, function (key, value) {
                                var $option = $("<option/>", {
                                    value: value.RateCodeId,
                                    text: value.RateCode,
                                });
                                $mySelect.append($option);
                            });
                        } else {
                            $(".rate-select").html(
                                '<option  value="0" selected="">Rate Class</option>'
                            );

                            this.rateCodeData = [];
                        }

                        // Utility Account Validation start here
                        this.utilityAccountValidation = result.data.utilityAccountData;
                        // Utility Account Validation end here
                    }
                })
                .catch((err) => {
                    console.log(err.message);
                });
        },
        // Edit Customer Get zone data ,rate class data ,volumn data end here

        // User Permission data api start
        getUserProfile() {
            axios({
                method: "post",
                url: constant.apiUrl + "/getUserProfile",
                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": localStorage.getItem("token"),
                },
                data: {
                    user_id: localStorage.getItem("userId"),
                    user_role: localStorage.getItem("role"),
                },
            })
                .then((result) => {
                    if (result.status === 200) {
                        this.userData = result.data.data;
                    }
                })
                .catch((err) => {
                    console.log("Error", err.message);
                });
        },
        // User Permission data api end
        // ******************reset sorting filter start************
        resetSortingFilter() {
            $('#filterSupplier').prop('selectedIndex', 0);
            $('#sorting-filter').prop('selectedIndex', 0);
            $("#term-0").css({
                background: "rgb(2, 149, 79)",
                color: "rgb(255, 255, 255)",
                textdecoration: "none"
            });
            $("#term-1,#term-2,#term-3").css({
                textdecoration: "none",
                background: "rgb(227, 227, 227)",
                color: "rgb(0, 0, 0)"
            })
            this.filterProvider = "0";
        },
        // ******************reset sorting filter end************

        // Step 1 form sumbmi start here
        stepOneSubmit(event, serviceTotalAnnualUsage) {
            if (event) { event?.preventDefault() };
            this.resetSortingFilter();
            this.errors = [];

            $(".step-2").hide();

            this.serviceAddressAlert = null;
            $("#back-button-first-step").show();

            $(".account_other_error").html("");
            let currentDay = new Date().getDate();
            let currentMonth = new Date().getMonth();
            let currentYear = new Date().getFullYear();

            //Error handling start 
            if (this.$refs["utility"].value == "0") {
                this.errors.push({
                    utility: "Utility is required"
                });
                $("html, body").animate({
                    scrollTop: $("#utility").offset().top,
                },
                    500
                );
                return false;
            }
            if (
                // If current day is past the 15th, the month MUST be the next month or later  
                ((this.$refs["startYear"]?.value == currentYear) && (currentDay >= 15) && (this.$refs["startMonth"]?.value <= currentMonth + 1)) ||
                // If current day is before the 15th, the month MUST be the current month or later
                ((this.$refs["startYear"]?.value == currentYear) && (currentDay < 15) && (this.$refs["startMonth"]?.value <= currentMonth))
            ) {
                this.errors.push({
                    startDate: "Please pick a future month."
                });
                $("html, body").animate({
                    scrollTop: $("#startDate").offset().top,
                },
                    500
                );
                return false;
            }

            if (!!this.$refs["startDate"] && !this.$refs["startDate"].value) {
                this.errors.push({
                    startDate: "Start Date is required"
                });
                $("html, body").animate({
                    scrollTop: $("#startDate").offset().top,
                },
                    500
                );
                return false;
            }
            if (!!this.$refs["startDate"] && !this.$refs["startDate"].value.endsWith("01")) {
                this.errors.push({
                    startDate: "Date must be the first of the month"
                });
                $("html, body").animate({
                    scrollTop: $("#startDate").offset().top,
                },
                    500
                );
                return false;
            }
            if (this.$refs["product"].value == "1") {
                if (($("#rate-select").val() == "0") && (!this.editRateInfo.rateClass)) {
                    this.errors.push({
                        rateClass: "Rate Class is required"
                    });
                    $("html, body").animate({
                        scrollTop: $("#rate-select").offset().top,
                    },
                        500
                    );
                    return false;
                }
            }
            if ($("#annual-usage").val() == "") {
                this.errors.push({
                    annualusage: "Annual Usage is required"
                });
                $("html, body").animate({
                    scrollTop: $("#annual-usage").offset().top,
                },
                    500
                );
                return false;
            }
            //Error handling end

            var accountRateClassAnnualUsageData = this.allAccountRateClassAnnualUsageData;
            const billCopyData = this.allAccountRateClassAnnualUsageData;
            var billCopyFiltered = billCopyData.filter(function (el) {
                return el.sno == 1;
            });

            if (billCopyFiltered[0] !== undefined) {
                var billcopyimage = billCopyFiltered[0].billcopyimage;
                var esid = billCopyFiltered[0].esid;
                var address = billCopyFiltered[0].address;
                var zip = billCopyFiltered[0].zip;
                var city = billCopyFiltered[0].city;
                var statevalue = billCopyFiltered[0].statevalue;
                var statetext = billCopyFiltered[0].statetext;
            } else {
                var billcopyimage = "";
                var esid = "";
                var address = "";
                var zip = "";
                var city = "";
                var statevalue = "";
                var statetext = "";
            }

            var params = {};
            params["annualusage"] = parseInt($("#annual-usage").val());
            //params["ratetext"] = this.edit_customer ? this.editRateInfo.rateClass : $("#rate-select option:selected").text();
            params["ratetext"] = $("#rate-select option:selected").text();
            // params["ratevalue"] = this.edit_customer? this.editRateInfo.rateCodeId : $("#rate-select").val();
            params["ratevalue"] = $("#rate-select").val();
            params["sno"] = 1;
            params["statetext"] = statetext;
            params["statevalue"] = statevalue;
            params["city"] = city;
            params["zip"] = zip;
            params["address"] = address;
            params["esid"] = esid;
            params["billcopyimage"] = billcopyimage;
            if (this.allAccountRateClassAnnualUsageData.length == 0) {
                console.log("allAccountRateClassAnnualUsageData length is zero");
                accountRateClassAnnualUsageData.push(params);
            }

            var totalAnualUsage = parseInt($("#annual-usage").val());

            const totalAccountNum = $("#account-no").val();
            const updatedAccountNum = parseInt(totalAccountNum) - parseInt(1);
            const customerId = this.customerId;

            if (serviceTotalAnnualUsage === 0 && customerId == "0") {
                this.allAccountRateClassAnnualUsageData = [];
                $("#setp-3-form").trigger("reset");
                this.customerId = "0";
            } else {
                this.allAccountRateClassAnnualUsageData = [];
            }

            if (updatedAccountNum) {
                for (var i = 2; i <= updatedAccountNum; i++) {
                    var billCopyFiltered = billCopyData.filter(function (el) {
                        return el.sno == i;
                    });

                    if (billCopyFiltered[0] !== undefined) {
                        var billcopyimage = billCopyFiltered[0].billcopyimage;
                        var esid = billCopyFiltered[0].esid;
                        var address = billCopyFiltered[0].address;
                        var zip = billCopyFiltered[0].zip;
                        var city = billCopyFiltered[0].city;
                        var statevalue = billCopyFiltered[0].statevalue;
                        var statetext = billCopyFiltered[0].statetext;
                    } else {
                        var billcopyimage = "";
                        var esid = "";
                        var address = "";
                        var zip = "";
                        var city = "";
                        var statevalue = "";
                        var statetext = "";
                    }

                    var params = {};

                    params["annualusage"] = parseInt($("#annual-usage" + "-" + i).val());
                    params["ratetext"] = $("#rate" + "-" + i + " option:selected").text();
                    params["ratevalue"] = $("#rate" + "-" + i).val();
                    params["sno"] = i;
                    params["statetext"] = statetext;
                    params["statevalue"] = statevalue;
                    params["city"] = city;
                    params["zip"] = zip;
                    params["address"] = address;
                    params["esid"] = esid;
                    params["billcopyimage"] = billcopyimage;

                    if (this.ProductId == "1") {
                        if ($("#rate" + "-" + i).val() == "0") {
                            $("#rate" + "-" + i).after(
                                '<span class="account_other_error" style="color:red;">Rate Class is required</span>'
                            );
                            $("html, #body").animate({
                                scrollTop: $("#rate" + "-" + i).offset().top,
                            },
                                500
                            );
                            return false;
                        }
                    }

                    if ($("#annual-usage" + "-" + i).val() == "") {
                        $("#annual-usage" + "-" + i).after(
                            '<span class="account_other_error" style="color:red;">Annual Usage is required</span>'
                        );
                        $("html, #body").animate({
                            scrollTop: $("#annual-usage" + "-" + i).offset().top,
                        },
                            500
                        );
                        return false;
                    }
                    accountRateClassAnnualUsageData.push(params);
                    totalAnualUsage =
                        totalAnualUsage + parseInt($("#annual-usage" + "-" + i).val());

                }
                this.allAccountRateClassAnnualUsageData = accountRateClassAnnualUsageData;
            }
            if (serviceTotalAnnualUsage === 0) {
                this.oldPrice =
                    parseFloat(totalAnualUsage) * parseFloat($("#customerRate").val());

                var annualUsageTotal = totalAnualUsage;

                totalAnualUsage;
            } else {
                $(".step-3").hide();

                this.oldPrice =
                    parseFloat(serviceTotalAnnualUsage) * parseFloat($("#customerRate").val());

                var annualUsageTotal = serviceTotalAnnualUsage;
            }

            this.annualUsageTotal = annualUsageTotal;

            if ($("#customerRate").val() == "") {
                this.customerRate = "0";
            } else {
                this.customerRate = parseFloat($("#customerRate").val());
            }

            if ($("#margin").val() == "") {
                this.marginRate = "0";
            } else {
                this.marginRate = parseFloat($("#margin").val());
            }

            $("#customer_loader").show();
            $("#main-section").hide();

            // Safari browser date Changes Start

            let startDateFormat;
            let startDateMDY;
            let stringDate;

            if (this.$refs["startDate"]?.value) {
                stringDate = this.$refs["startDate"].value;
                let dateArray = stringDate.split("-");
                startDateFormat = [dateArray[0], dateArray[1]].join("-");
                startDateMDY = [dateArray[1], dateArray[2], dateArray[0]].join("-");
            }

            if ((this.$refs["startYear"]?.value) && (this.$refs["startMonth"]?.value)) {
                let month = parseInt(this.$refs["startMonth"].value) + 1;
                let monthValAsString = month.toString();
                if (monthValAsString.length === 1) {
                    monthValAsString = "0" + monthValAsString;
                }
                let year = this.$refs["startYear"].value;
                startDateFormat = year + "-" + monthValAsString;
                startDateMDY = monthValAsString + "-01-" + year;
                stringDate = year + "-" + monthValAsString + "-01"
            }

            let productData = {
                product: this.$refs["product"].value,
                utility: $("#utility option:selected").text(),
                utilityId: $("#utility").val(),
                utilityName: $("#utility option:selected").attr("data-text"),
                startDate: startDateFormat,
                annualUsage: annualUsageTotal,
                zone: $("#zone option:selected").text(),
                rate_class: this.edit_customer ? this.editRateInfo.rateClass : $("#rate-select option:selected").text(),
                RateCodeId: this.edit_customer ? this.editRateInfo.rateCodeId.toString() : $("#rate-select").val(),
                StateCode: $("#state option:selected").attr("data-text"),
                utilityIdPriceQuoteAdd: this.utilityIdPriceQuoteAdd.toString(),
                demand: $("#demand").val()
            }
            console.log("Plan data:" + JSON.stringify(productData))

            axios({
                method: "post",
                url: constant.apiUrl + "/getPlan",
                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": localStorage.getItem("token"),
                },
                data: productData,
            })
                .then((result) => {
                    if (result.status === 200) {
                        this.queryResult = result.data;
                        if (result.data.bestPlanEveryProviderData.length === 0) {
                            $("#customer_loader").hide();
                            $("#main-section").show();
                            $(".step-1").show();
                            $(".step-2").hide();
                            alert("No Pricing Available");
                            return false;
                        }
                        // Loader Work Start
                        $("#customer_loader").hide();
                        $("#main-section").show();
                        // Loader Work End

                        $(".step-1").hide();
                        $(".step-2").show();
                        window.scrollTo(0, 0);
                        $(".one").removeClass("active");
                        $(".two").addClass("active");
                        $(".one").addClass("complt");
                        $(".one").html(
                            '<h6>1</h6> <h5><img src="images/green-tick.png" class="done done-step-one" data-status="1"/></h5><span>Price Quote</span>'
                        );
                        $(".done,.button-two").css("cursor", "pointer");
                        $(".button-three,.button-four").css("cursor", "not-allowed");
                        $(".button-two").attr("data-status", "1");
                        $(".button-three,.button-four").attr("data-status", "0");

                        this.searchData = result.data.bestPlanEveryProviderData;
                        console.log(JSON.stringify(result));
                        var chariotFind = result.data.bestPlanEveryProviderData.find(
                            (item) => item.providername === "Chariot Energy"
                        );
                        console.log("chariotFind: " + JSON.stringify(chariotFind));
                        // Toggle message about TOU
                        if(chariotFind != undefined){
                            this.showTouMessage = true;
                        }
                        this.providerNotesData = result.data.providerNotesData;

                        // rate remove 0 start
                        var rate = [];
                        var marginRate = this.marginRate;

                        result.data.bestPlanProvider.map((item) => {
                            var a = (parseFloat(item.price) + parseFloat(marginRate)).toFixed(5);
                        });

                        // rate remove 0 end

                        this.maxTermBestPlanData = result.data.bestPlanProvider.sort(
                            (a, b) => b.terms - a.terms
                        )[0];

                        // const maxtermsOfBestPlan = this.maxTermBestPlanData.terms;
                        if (this.maxTermBestPlanData.terms > 60) {

                            var maxtermsOfBestPlan = 60;

                        } else {
                            var maxtermsOfBestPlan = this.maxTermBestPlanData.terms;
                        }

                        this.bestPlanProviderData = result.data.bestPlanProvider.filter(function (
                            item
                        ) {

                            if (item.terms % 12 == 0 && item.terms < maxtermsOfBestPlan) {
                                return true;
                            }
                            return false;
                        });

                        this.bestPlanProviderData.sort(function (a, b) {
                            return a.terms - b.terms;
                        });

                        this.uniqueProviderData = result.data.providerNotesData;
                        this.searchDataConstant = result.data.bestPlanEveryProviderData;

                        $("#plan-utility").val($("#utility option:selected").text());
                        $("#plan-start-date").val(stringDate);
                        $("#plan-start-date-formated").val(startDateMDY);

                        $("#plan-annual-usage").val(annualUsageTotal);
                        this.totalAnualUsageService = annualUsageTotal;
                        $("#plan-customer-rate").val($("#customerRate").val());
                        $("#plan-zone").val($("#zone option:selected").text());

                        if ($("#margin").val() && $("#margin").val() != "0.00000") {
                            $("#plan-margin").val($("#margin").val());

                        }
                    } else if (result.status === 202) {
                        $("#customer_loader").hide();
                        $("#main-section").show();
                        alert("No Pricing Available");
                    } else {
                        $("#customer_loader").hide();
                        $("#main-section").show();
                        alert("something went wrong");
                    }
                })
                .catch((err) => {
                    $("#customer_loader").hide();
                    $("#main-section").show();
                    console.log("bad request reason: " + JSON.stringify(err));
                });
        },
        // Step 1 form sumbmit end here

        // Step 2 form sumbmit start here
        stepTwoSubmit(e, Term, Rate, Provider, ProviderId, ProviderSalesForceId, item) {
            e.preventDefault();
            this.selectedItem = item;
            if ($("#state").val() === "0") {
                $("#info-StateId").val("1");
            } else {
                $("#info-StateId").val($("#state").val());
            }

            $("#back-button-second-step").show();

            $(this).css("background", "#02954f");
            $(this).css("color", "white");

            $("#hidden-Term").val(Term);
            $("#hidden-Rate").val(Rate);

            let margin = $("#margin").val();
            let transferRate = (Rate - margin).toFixed(5).replace(/^0+/, '');
            $("#transfer-rate").val(transferRate);
            $("#visible-rate").css("display", "block");
            $("#hidden-Provider").val(Provider);
            $("#hidden-ProviderId").val(ProviderId);
            $("#hidden-ProviderSalesForceId").val(ProviderSalesForceId);

            $(".step-1,.step-2").hide();
            $(".step-3").show();
            window.scrollTo(0, 0);
            $(".two").removeClass("active");
            $(".three").addClass("active");
            $(".two").addClass("complt");
            $(".two").html(
                '<h6>1</h6> <h5><img src="images/green-tick.png" class="done done-step-two" data-step="1"/></h5><span>Select Plan</span>'
            );
            $(".done,.button-three").css("cursor", "pointer");
            $(".button-four").css("cursor", "not-allowed");
            $(".button-three").attr("data-status", "1");
            $(".button-four").attr("data-status", "0");
        },
        // Step 2 form sumbmit end here

        // Sorting serach record bases on term,rate,supplier start
        sortingSearch(event) {
            const searchData = this.searchData;
            const sortValue = event.target.value;

            if (sortValue == "0") {
                return false;
            } else if (sortValue == "1") {
                searchData.sort(function (a, b) {
                    return a.terms - b.terms;
                });
            } else if (sortValue == "2") {
                searchData.sort(function (a, b) {
                    return a.price - b.price;
                });
            } else if (sortValue == "3") {
                searchData.sort(function (a, b) {
                    return a.providername.localeCompare(b.providername, undefined, {
                        ignorePunctuation: true,
                    });
                });

                this.searchData = searchData;
            }
        },
        // Sorting serach record bases on term,rate,supplier start

        // Filter search reord bases on term start
        filterSearchTerm(min, max, id) {
            this.filterTermMin = min;
            this.filterTermMax = max;

            $(".sorting-term").css("background", "#e3e3e3");
            $(".sorting-term").css("color", "#000");
            $(`#${id}`).css("background", "#02954f");
            $(`#${id}`).css("color", "white");

            const searchData = this.searchDataConstant;

            const providerId = this.filterProvider;

            if (min == "0" && max == "0") {
                if (providerId === "0") {
                    this.searchData = this.searchDataConstant;
                    return false;
                } else {
                    var filterSearch = searchData.filter(function (el) {
                        return el.providerId == providerId;
                    });
                }
            } else {
                if (providerId === "0") {
                    var filterSearch = searchData.filter(function (el) {
                        return el.terms <= max && el.terms >= min;
                    });
                } else {
                    var filterSearch = searchData.filter(function (el) {
                        return el.providerId == providerId && el.terms <= max && el.terms >= min;
                    });
                }
            }

            this.searchData = filterSearch;
        },
        // Filter search reord bases on term start

        // Filter search reord bases on provider start
        filterSearchProvider(event) {
            this.filterProvider = event.target.value;
            const providerId = event.target.value;

            var min = this.filterTermMin;
            var max = this.filterTermMax;
            var searchData = this.searchDataConstant;

            if (providerId == "0") {
                if (min == "0" && max == "0") {
                    this.searchData = this.searchDataConstant;
                    return false;
                } else {
                    var filterSearch = searchData.filter(function (el) {
                        return el.terms <= max && el.terms >= min;
                    });
                }
            } else {
                if (min == "0" && max == "0") {
                    var filterSearch = searchData.filter(function (el) {
                        return el.providerId == providerId;
                    });
                } else {
                    var filterSearch = searchData.filter(function (el) {
                        return el.providerId == providerId && el.terms <= max && el.terms >= min;
                    });
                }
            }

            this.searchData = filterSearch;
        },
        // Filter search reord bases on provider end

        // Get Product data  Api  start here
        getProducts(e) {
            axios({
                method: "get",
                url: constant.apiUrl + "/getProducts",
                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": localStorage.getItem("token"),
                },
                data: {},
            })
                .then((result) => {
                    if (result.status === 200) {
                        this.productData = result.data.data;
                    }
                })
                .catch((err) => {
                    console.log(err.message);
                });
        },
        // Get Product data  Api  end here
        // Step 3 form submit start here
        stepThreeSubmit(event, id) {
            event?.preventDefault();
            this.errors = [];
            this.saveQuoteAlert = null;

            const term = parseInt($("#hidden-Term").val());
            var d = new Date($("#plan-start-date").val());

            d.setMonth(d.getMonth() + term);

            var month = "" + (d.getMonth() + 1);
            var day = "" + d.getDate();
            if (month.length < 2) {
                var month = "0" + "" + (d.getMonth() + 1);
            }

            if (day.length < 2) {
                var day = "0" + "" + d.getDate();
            }
            const EndDate = [d.getFullYear(), month, day].join("-");

            if (!this.$refs["CompanyName"].value) {
                this.errors.push({
                    CompanyName: "Business Name is required"
                });
                $("html, body").animate({
                    scrollTop: $("#CompanyName").offset().top,
                },
                    500
                );
                return false;
            }

            if (this.accountOrEsid == "2") {
                var accountOrEsid = "ESID";
            } else {
                var accountOrEsid = "Account Number";
            }

            if (!this.$refs["ContactFirstName"].value) {
                this.errors.push({
                    ContactFirstName: "First Name is required"
                });
                $("html, body").animate({
                    scrollTop: $("#ContactFirstName").offset().top,
                },
                    500
                );
                return false;
            }

            if (!this.$refs["ContactLastName"].value) {
                this.errors.push({
                    ContactLastName: "Last Name is required"
                });
                $("html, body").animate({
                    scrollTop: $("#ContactLastName").offset().top,
                },
                    500
                );
                return false;
            }
            if (!this.$refs["JobTitle"].value) {
                this.errors.push({
                    JobTitle: "Job Title is required"
                });
                $("html, body").animate({
                    scrollTop: $("#JobTitle").offset().top,
                },
                    500
                );
                return false;
            }

            if (!this.$refs["Phone"].value) {
                this.errors.push({
                    Phone: "Phone is required"
                });
                $("html, body").animate({
                    scrollTop: $("#Phone").offset().top,
                },
                    500
                );
                return false;
            }

            if (this.$refs["Phone"].value.length != 10) {
                this.errors.push({
                    Phone: "Phone should be only 10 digits",
                });
                $("html, body").animate({
                    scrollTop: $("#Phone").offset().top,
                },
                    500
                );
                return false;
            }

            if (!this.$refs["Email"].value) {
                this.errors.push({
                    Email: "Email is required"
                });
                $("html, body").animate({
                    scrollTop: $("#Email").offset().top,
                },
                    500
                );
                return false;
            }

            const pattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
            if (!pattern.test(this.$refs["Email"].value)) {
                this.errors.push({
                    Email: "Email must be valid format"
                });
                $("html, body").animate({
                    scrollTop: $("#Email").offset().top,
                },
                    500
                );
                return false;
            }

            if (!this.$refs["cnfEmail"].value) {
                this.errors.push({
                    cnfEmail: "Confirm Email is required"
                });
                $("html, body").animate({
                    scrollTop: $("#cnfEmail").offset().top,
                },
                    500
                );
                return false;
            }

            if (this.$refs["cnfEmail"].value !== this.$refs["Email"].value) {
                this.errors.push({
                    cnfEmail: "Email & Confirm Email should be same"
                });
                $("html, body").animate({
                    scrollTop: $("#cnfEmail").offset().top,
                },
                    500
                );
                return false;
            }

            if (!this.$refs["Street"].value) {
                this.errors.push({
                    Street: "Street is required"
                });
                $("html, body").animate({
                    scrollTop: $("#Street").offset().top,
                },
                    500
                );
                return false;
            }

            if (!this.$refs["City"].value) {
                this.errors.push({
                    City: "City is required"
                });
                $("html, body").animate({
                    scrollTop: $("#City").offset().top,
                },
                    500
                );
                return false;
            }

            if (!this.$refs["Zip"].value) {
                this.errors.push({
                    Zip: "Zip is required"
                });
                $("html, body").animate({
                    scrollTop: $("#Zip").offset().top,
                },
                    500
                );
                return false;
            }
            console.log("visible: " + $("#visible-rate").val())
            if ($("#visible-rate").val() <= 0) {
                this.errors.push({
                    ContractRate: "Contract rate is required (Transer Rate + Margin)"
                });
                $("html, body").animate({
                    scrollTop: $("#visible-rate").offset().top,
                },
                    500
                );
                return false;
            }
            const ServiceData = this.allAccountRateClassAnnualUsageData;

            const last_element = ServiceData[ServiceData.length - 1];
            const totalsno = parseInt(last_element.sno);

            $(".service-account_error").remove("");

            for (var i = 1; i <= totalsno; i++) {

                if ($("#service-account" + "-" + i).text() == "") {
                    $("#service-account" + "-" + i).html(
                        `<span class="service-account_error" style="color:red;text-align: center;">${accountOrEsid} is required</span>`
                    );
                    $("html, #body").animate({
                        scrollTop: $("#service-account" + "-" + i).offset().top,
                    },
                        500
                    );
                    return false;
                }

                if ($("#service-address" + "-" + i).text() == "") {
                    $("#service-address" + "-" + i).html(
                        '<span class="service-account_error" style="color:red;text-align: center;">Address is required</span>'
                    );
                    $("html, #body").animate({
                        scrollTop: $("#service-address" + "-" + i).offset().top,
                    },
                        500
                    );
                    return false;
                }

                if ($("#service-citystatezip" + "-" + i).text() == "") {
                    $("#service-citystatezip" + "-" + i).after(
                        '<span class="service-account_error" style="color:red;">State/City/Zip is required</span>'
                    );
                    $("html, #body").animate({
                        scrollTop: $("#service-citystatezip" + "-" + i).offset().top,
                    },
                        500
                    );
                    return false;
                }
            }

            localStorage.removeItem("finalData");
            localStorage.removeItem("serviceAddressData");

            const finalData = {
                AccountNumber: $("#AccountNumber").val(),
                CompanyName: $("#CompanyName").val(),
                ContactFirstName: $("#ContactFirstName").val(),
                ContactLastName: $("#ContactLastName").val(),
                JobTitle: $("#JobTitle").val(),
                Phone: $("#Phone").val(),
                Email: $("#Email").val(),
                City: $("#City").val(),
                StateId: $("#info-StateId").val(),
                StateText: $("#info-StateId option:selected").text(),
                StateTextName: $("#info-StateId option:selected").attr("data-text"),
                Zip: $("#Zip").val(),
                Street: $("#Street").val(),
                Term: parseInt($("#hidden-Term").val()),
                Rate: $("#visible-rate").val(),
                Quantity: parseInt($("#plan-annual-usage").val()),
                CustomersRate: $("#plan-customer-rate").val(),
                Margin: $("#plan-margin").val(),
                ZoneId: parseInt($("#zone").val()),
                UserIdAdded: localStorage.getItem("userId"),
                customerId: this.customerId,
                UtilityId: $("#utility").val(),
                StartDate: $("#plan-start-date").val(),
                UtilityText: $("#plan-utility").val(),
                ProviderText: $("#hidden-Provider").val(),
                ProviderId: parseInt($("#hidden-ProviderId").val()),
                Notes: $("#Notes").val(),
                EndDate: EndDate,
                Demand: parseInt($("#demand").val()),
                Product: parseInt($("#product").val()),
                SaleType: parseInt($("#SaleType").val()),
                Unit: $(".box-rate").first().text(),
                SalesforceUtilityId: $("#utility option:selected").attr("data-salesforceId"),
                SalesforceSupplierId: $("#hidden-ProviderSalesForceId").val(),
                firstName: localStorage.getItem("firstName"),
                lastName: localStorage.getItem("lastName"),
                SubAgentId: JSON.parse(localStorage.getItem("salesforceContactId"))
            };

            localStorage.setItem("finalData", JSON.stringify(finalData));
            localStorage.setItem("serviceAddressData", JSON.stringify(ServiceData));
            const retrievedfinalData = finalData;


            const retrievedserviceAddressData = JSON.parse(
                localStorage.getItem("serviceAddressData")
            );

            if (id && id === 1) {
                $(".step-1,.step-2,.step-3").hide();

                window.scrollTo(0, 0);
                $(".three").removeClass("active");
                $(".three").addClass("complt");
                $(".three").html(
                    '<h6>1</h6> <h5><img src="images/green-tick.png" class="done done-step-three" data-status="1"/></h5><span>Enter Info</span>'
                );
                $(".done").css("cursor", "pointer");
                $(".button-four").css("cursor", "not-allowed");
                $("button-four").attr("data-status", "0");
            }
            else {
                axios({
                    method: "post",
                    url: constant.apiUrl + "/addCustomer",
                    headers: {
                        "Content-Type": "application/json",
                        "x-access-token": localStorage.getItem("token"),
                    },

                    data: {
                        customerData: retrievedfinalData,
                        serviceAddressData: retrievedserviceAddressData,
                        customerId: this.customerId,
                        Status: 0,
                    },
                })
                    .then((result) => {
                        if (result.status === 201) {
                            this.customerId = result.data.userid;
                            window.scrollTo(0, 0);
                            this.saveQuoteAlert = "Quote Saved Successfully";
                            setTimeout(() => (this.saveQuoteAlert = false), 3000);
                        } else {
                            alert("Something went wrong");
                        }
                    })
                    .catch((err) => {
                        console.log(err.message);
                    });
            }
        },
        // Step 3 form sumbmit end here

        // Step 4 from submit start here
        async submitOpportunity(e) {
            e.preventDefault();
            this.errors = [];
            this.saveQuoteAlert = null;
            if (!this.$refs["CompanyName"].value) {
                this.errors.push({
                    CompanyName: "Business Name is required"
                });
                $("html, body").animate({
                    scrollTop: $("#CompanyName").offset().top,
                },
                    500
                );
                return false;
            }
            if (this.accountOrEsid == "2") {
                var accountOrEsid = "ESID";
            } else {
                var accountOrEsid = "Account Number";
            }
            if (!this.$refs["ContactFirstName"].value) {
                this.errors.push({
                    ContactFirstName: "First Name is required"
                });
                $("html, body").animate({
                    scrollTop: $("#ContactFirstName").offset().top,
                },
                    500
                );
                return false;
            }
            if (!this.$refs["ContactLastName"].value) {
                this.errors.push({
                    ContactLastName: "Last Name is required"
                });
                $("html, body").animate({
                    scrollTop: $("#ContactLastName").offset().top,
                },
                    500
                );
                return false;
            }
            if (!this.$refs["JobTitle"].value) {
                this.errors.push({
                    JobTitle: "Job Title is required"
                });
                $("html, body").animate({
                    scrollTop: $("#JobTitle").offset().top,
                },
                    500
                );
                return false;
            }
            if (!this.$refs["Phone"].value) {
                this.errors.push({
                    Phone: "Phone is required"
                });
                $("html, body").animate({
                    scrollTop: $("#Phone").offset().top,
                },
                    500
                );
                return false;
            }
            if (this.$refs["Phone"].value.length != 10) {
                this.errors.push({
                    Phone: "Phone should be only 10 digits",
                });
                $("html, body").animate({
                    scrollTop: $("#Phone").offset().top,
                },
                    500
                );
                return false;
            }
            if (!this.$refs["Email"].value) {
                this.errors.push({
                    Email: "Email is required"
                });
                $("html, body").animate({
                    scrollTop: $("#Email").offset().top,
                },
                    500
                );
                return false;
            }
            const pattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
            if (!pattern.test(this.$refs["Email"].value)) {
                this.errors.push({
                    Email: "Email must be valid format"
                });
                $("html, body").animate({
                    scrollTop: $("#Email").offset().top,
                },
                    500
                );
                return false;
            }
            if (!this.$refs["cnfEmail"].value) {
                this.errors.push({
                    cnfEmail: "Confirm Email is required"
                });
                $("html, body").animate({
                    scrollTop: $("#cnfEmail").offset().top,
                },
                    500
                );
                return false;
            }
            if (this.$refs["cnfEmail"].value !== this.$refs["Email"].value) {
                this.errors.push({
                    cnfEmail: "Email & Confirm Email should be same"
                });
                $("html, body").animate({
                    scrollTop: $("#cnfEmail").offset().top,
                },
                    500
                );
                return false;
            }
            if (!this.$refs["Street"].value) {
                this.errors.push({
                    Street: "Street is required"
                });
                $("html, body").animate({
                    scrollTop: $("#Street").offset().top,
                },
                    500
                );
                return false;
            }
            if (!this.$refs["City"].value) {
                this.errors.push({
                    City: "City is required"
                });
                $("html, body").animate({
                    scrollTop: $("#City").offset().top,
                },
                    500
                );
                return false;
            }
            if (!this.$refs["Zip"].value) {
                this.errors.push({
                    Zip: "Zip is required"
                });
                $("html, body").animate({
                    scrollTop: $("#Zip").offset().top,
                },
                    500
                );
                return false;
            }
            if ($("#visible-rate").val() <= 0) {
                this.errors.push({
                    ContractRate: "Contract rate is required (Transer Rate + Margin)"
                });
                $("html, body").animate({
                    scrollTop: $("#visible-rate").offset().top,
                },
                    500
                );
                return false;
            }
            $("#ExistingAccounts").val(0);
            $("#customer_loader").show();
            $("#NintexDocGenPackage").html("")
            $("#submit-contract").text("Please wait ...").attr("disabled", "disabled");

            const oldData = this.allAccountRateClassAnnualUsageData;

            var last_element = oldData[oldData.length - 1];
            const sno = parseInt(last_element.sno);

            console.log("allAccountRateClassAnnualUsageData: " + this.allAccountRateClassAnnualUsageData)
            console.log("SupplierIdSalesforceId: " + $("#hidden-ProviderSalesForceId").val())
            console.log("SupplierId: " + $("#hidden-ProviderId").val())
            console.log("AnnualUsage: " + $("#annual-usage").val())
            console.log("ProductId: " + this.ProductId);
            console.log("Demand: " + $("#demand").val());
            console.log("StateCode: " + $("#utility option:selected").attr("data-stateCode"));
            console.log("Sno: " + JSON.stringify(sno));
            console.log("UtilityId: " + $("#utility").val());

            axios({
                method: "post",
                url: constant.apiUrl + "/NintextDocGenPackagesList",
                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": localStorage.getItem("token"),
                },

                data: {
                    SupplierIdSalesforceId: $("#hidden-ProviderSalesForceId").val(),
                    SupplierId: $("#hidden-ProviderId").val(),
                    AnnualUsage: $("#annual-usage").val(),
                    ProductId: this.ProductId,
                    Demand: $("#demand").val(),
                    StateCode: $("#utility option:selected").attr("data-stateCode"),
                    Sno: sno,
                    UtilityId: $("#utility").val(),
                },
            })
                .then((result) => {
                    console.log("NintexDocGen Result: " + JSON.stringify(result.data.data));

                    if (result.data.data.length > 0) {
                        console.log("DocGen length not 0");
                        let selectValues = result.data.data;
                        this.docGenContractData = {
                            value: selectValues[0].SalesforceId,
                            deliveryOptionId: selectValues[0].DeliveryOptionId
                        }

                        this.stepSalesForece();

                    } else {
                        console.log("no docgen package found")
                        $("#NintexDocGenPackage").html(`<option value=" " dataDeliveryOptionId=" " selected>None</option>`)
                        $("#docGenStatusMessage").text("We could not find an appropriate contract for this deal.  Please reach out to daultman@integrityenergy.com for assistance in generating this contract")
                        this.stepSalesForece();
                    }
                })
                .catch((err) => {
                    console.log(err.message);
                });
            //wont be triggered if DocGen list is more than zero. So that's why Step 
            await this.stepThreeSubmit(e);
        },
        // Step 4 from submit end here

        // Salesforce Api start here
        stepSalesForece(e) {
            $("#customer_loader").show();
            e?.preventDefault();
            if ($("#ExistingAccounts").val() == "0" || $("#ExistingAccounts").val() == null) {
                $("#submit-contract-salesforce-existing-accounts").text("Submit Opportunity").prop("disabled", false);
            } else {
                $("#submit-contract-salesforce-existing-accounts").text("Please wait ...").attr("disabled", "disabled");
            }

            $("#submit-contract-salesforce")
                .text("Please wait ...")
                .attr("disabled", "disabled");
            $("#generateContractSection").show();
            this.contractGenerationAlert = null;

            console.log("NintexDocGenPackage", $("#NintexDocGenPackage").val());

            console.log(
                "DeliveryOptionId",
                $("#NintexDocGenPackage option:selected").attr("dataDeliveryOptionId")
            );
            // return false;

            const retrievedserviceAddressData = JSON.parse(
                localStorage.getItem("serviceAddressData")
            );

            //End Date logic
            const term = parseInt($("#hidden-Term").val());
            var d = new Date($("#plan-start-date").val());
            d.setMonth(d.getMonth() + term);
            var month = "" + (d.getMonth() + 1);
            var day = "" + d.getDate();

            if (month.length < 2) {
                var month = "0" + "" + (d.getMonth() + 1);
            }
            if (day.length < 2) {
                var day = "0" + "" + d.getDate();
            }
            console.log("month: " + month)
            console.log("day: " + day)
            const endDate = [d.getFullYear(), month, day].join("-");

            const formData = {
                AccountNumber: $("#AccountNumber").val(),
                CompanyName: $("#CompanyName").val(),
                ContactFirstName: $("#ContactFirstName").val(),
                ContactLastName: $("#ContactLastName").val(),
                JobTitle: $("#JobTitle").val(),
                Phone: $("#Phone").val(),
                Email: $("#Email").val(),
                City: $("#City").val(),
                StateId: $("#info-StateId").val(),
                StateText: $("#info-StateId option:selected").text(),
                StateTextName: $("#info-StateId option:selected").attr("data-text"),
                Zip: $("#Zip").val(),
                Street: $("#Street").val(),
                Term: parseInt($("#hidden-Term").val()),
                Rate: $("#hidden-Rate").val(),
                Quantity: parseInt($("#plan-annual-usage").val()),
                CustomersRate: $("#plan-customer-rate").val(),
                Margin: $("#plan-margin").val(),
                ZoneId: parseInt($("#zone").val()),
                UserIdAdded: localStorage.getItem("userId"),
                customerId: this.customerId,
                UtilityId: $("#utility").val(),
                StartDate: $("#plan-start-date").val(),
                UtilityText: $("#plan-utility").val(),
                ProviderText: $("#hidden-Provider").val(),
                ProviderId: parseInt($("#hidden-ProviderId").val()),
                Notes: $("#Notes").val(),
                EndDate: endDate,
                Demand: parseInt($("#demand").val()),
                Product: parseInt($("#product").val()),
                SaleType: parseInt($("#SaleType").val()),
                Unit: $(".box-rate").first().text(),
                SalesforceUtilityId: $("#utility option:selected").attr("data-salesforceId"),
                SalesforceSupplierId: $("#hidden-ProviderSalesForceId").val(),
                firstName: localStorage.getItem("firstName"),
                lastName: localStorage.getItem("lastName"),
                SubAgentId: JSON.parse(localStorage.getItem("salesforceContactId"))
            };

            if (!$("#ExistingAccounts").val()) {
                $("#ExistingAccounts").val(0)
            }

            axios({
                method: "post",
                url: constant.apiUrl + "/addCustomer",
                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": localStorage.getItem("token"),
                },
                data: {
                    customerData: formData,
                    serviceAddressData: retrievedserviceAddressData,
                    customerId: this.customerId,
                    Status: 1,
                    NintexDocGenPackage: this.docGenContractData.value,
                    DeliveryOptionId: this.docGenContractData.deliveryOptionId,
                    SalesForceExistingAccountId: $("#ExistingAccounts").val(),
                    SalesForceExistingContactId: this.isExistingContact,

                },
            })
                .then((result) => {
                    if (result.status === 201) {
                        console.log("is 201")
                        console.log(JSON.stringify(result.data))
                        if (result.data.success === true) {
                            console.log("is success")
                            this.customerId = result.data.userid;
                            this.opportunityId = result.data.opportunityId;
                            $("#confirmModel").modal("hide");

                            //The 3 modals that actually could appear for forece

                            $("#LargeContractDisclaimer").css("display", "flex");
                            $("#NintextDocGenPackages").modal("hide");
                            $("#ExistingAccountsModel").modal("hide");
                            $("#ExistingContactsModel").modal("hide");

                            $("#customer_loader").show();
                            $("#main-section").hide();

                            $(".step-1,.step-2,.step-3").hide();
                            $(".final-step").show();
                            $("#customerEmail").text(formData.Email);

                            window.scrollTo(0, 0);
                            $(".four").addClass("active");
                            $(".done,.button-four").css("cursor", "pointer");
                            $(".button-four").attr("data-status", "1");

                            $("#setp-1-form,#setp-3-form").trigger("reset");

                            $(".done").css("cursor", "not-allowed");
                            $(".done").attr("data-status", "0");
                            // $(".submit-contract").prop("disabled", true);
                            if ($("#NintexDocGenPackage").val() !== " ") {
                                this.contractAxios();
                                //// Contract Generation End
                            } else {
                                $("#generateContractSection").hide();
                                $("#customer_loader").hide();
                                $("#main-section").show();
                            }
                        } else if (result.data.success === false) {
                            console.log("Add customer unsuccessful")
                            if (result.data?.errorType?.duplicateResult?.duplicateRuleEntityType === "Account") {
                                console.log("Failed on account creation")
                                if (result.data?.errorType?.duplicateResult?.duplicateRule === "Matching_Phone") {
                                    console.log("Account failed due to duplicate phone")
                                }
                                $("#submit-contract-salesforce-existing-accounts").text("Submit Opportunity").prop("disabled", false);

                                $("#confirmModel").modal("hide");
                                $("#NintextDocGenPackages").modal("hide");
                                $("#generateContractSection").hide();
                                $("#customer_loader").hide();
                                for (var i = 0; i < result?.data?.existingId?.duplicateResult?.matchResults[0]?.matchRecords?.length; i++) {
                                    axios({
                                        method: "post",
                                        url: constant.apiUrl + "/findAccountDetails",
                                        headers: {
                                            "Content-Type": "application/json",
                                            "x-access-token": localStorage.getItem("token"),
                                        },
                                        data: {
                                            accountId: result?.data?.existingId?.duplicateResult?.matchResults[0]?.matchRecords[i]?.record.Id,
                                        },
                                    }).then((result) => {
                                        console.log("result from finding account details: " + JSON.stringify(result))
                                        if (result.data.success === true) {
                                            if (result.data.data.Existing_SubAgent__c) {
                                                axios({
                                                    method: "post",
                                                    url: constant.apiUrl + "/findSubAgentDetails",
                                                    headers: {
                                                        "Content-Type": "application/json",
                                                        "x-access-token": localStorage.getItem("token"),
                                                    },
                                                    data: {
                                                        accountId: result.data.data.Existing_SubAgent__c
                                                    },

                                                }).then((contactResults) => {
                                                    this.existingAccountOptions.push({ value: result.data.data.Id, accountName: result.data.data.Name, subAgentName: contactResults.data.data.Name })
                                                    $('#ExistingAccounts').val(result.data.data.Id);
                                                    this.noSubAgentMessage = false;
                                                })
                                            }
                                            else {
                                                this.noSubAgentMessage = true;
                                            }
                                            $("#ExistingAccountsModel").modal('show');

                                        }
                                    }).catch((err) => {
                                        console.log("Find account details error: " + JSON.stringify(err))
                                        $("#customer_loader").hide();
                                        $("#main-section").show();
                                        alert(err.message);
                                        console.log(err.message);
                                    });
                                }

                            } else if (result?.data?.errorType?.duplicateResult?.duplicateRuleEntityType === "Contact") {
                                console.log("Contact error", result.data.errorType)
                                alert(`${result.data.errorType.name} Error: ${result.data.message}`);

                                $("#submit-contract-salesforce-existing-contacts").text("Submit Opportunity").prop("disabled", false);

                                $("#confirmModel").modal("hide");
                                $("#NintextDocGenPackages").modal("hide");
                                $("#generateContractSection").hide();
                                $("#customer_loader").hide();

                                axios({
                                    method: "post",
                                    url: constant.apiUrl + "/findContactDetails",
                                    headers: {
                                        "Content-Type": "application/json",
                                        "x-access-token": localStorage.getItem("token"),
                                    },
                                    data: {
                                        contactEmail: $("#cnfEmail").val(),
                                        contactPhone: $("#Phone").val(),
                                    },
                                }).then((result) => {
                                    console.log("Find contact details result: " + JSON.stringify(result))
                                    if (result.data.success === true) {
                                        this.isExistingContact = "1";

                                        let contact = result.data.data

                                        // this.existingAccountOptions.push({value:contact.Id, firstName:contact.FirstName, lastName: contact.LastName, Email: contact.email, Phone: contact.phone })

                                        $("#ExistingAccountsModel").modal('hide');
                                        $("#ExistingContactsModel").modal('show');

                                    }
                                }).catch((err) => {
                                    console.log("Find contact details error: " + JSON.stringify(err))
                                    $("#customer_loader").hide();
                                    $("#main-section").show();
                                    alert(err.message);
                                    console.log(err.message);
                                });

                            }
                            $("#customer_loader").hide();
                            return false

                        } else {
                            $("#customer_loader").hide();
                            alert("something went wrong");

                        }
                    }
                })
                .catch((err) => {
                    alert(err.message);
                    console.log(err.message);

                });
        },
        // Salesforce Api end here

        // Download Contract start here
        downloadContract(e) {
            $("#customer_loader").show();
            e?.preventDefault();

            $('#generate-contract').text('Please wait ...')
                .attr('disabled', 'disabled');

            const fileName = this.contractTitle;
            var myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${this.salesForceToken}`);

            var requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow",
            };
            fetch(
                `https://integrityenergy123.my.salesforce.com/services/data/v53.0/sobjects/contentversion/${this.contractLatestPublishedVersionId}/versiondata`,
                requestOptions
            )
                .then((response) => response.blob())
                .then((data) => {
                    $("#customer_loader").hide();
                    const url = URL.createObjectURL(data);
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", fileName);
                    document.body.appendChild(link);
                    link.click();
                    $("#generate-contract").text("Generate Contract").prop("disabled", false);

                })
                .catch((err) => {
                    $("#customer_loader").hide();
                    console.log(err.message);
                    $("#generate-contract").text("Generate Contract").prop("disabled", false);

                });
        },
        // Download Contract end here

        // Get Enable State only  Api  start here
        getStates(e) {
            axios({
                method: "get",
                url: constant.apiUrl + "/getStates",
                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": localStorage.getItem("token"),
                },
                data: {},
            })
                .then((result) => {
                    if (result.status === 200) {
                        this.stateData = result.data.enabledStateData;
                        this.allStateData = result.data.allStateData;
                    }
                })
                .catch((err) => {
                    console.log(err.message);
                });
        },
        // Get Enable State only  Api end here

        // Get Utility data  Api  start here
        getUtilities(e) {
            axios({
                method: "get",
                url: constant.apiUrl + "/getUtilitiesPriceQuote",
                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": localStorage.getItem("token"),
                },
                data: {},
            })
                .then((result) => {
                    if (result.status === 200) {
                        const ProductId = this.ProductId;
                        this.utilityData = result.data.data;
                        const utilityData = result.data.data;
                        const newArray = utilityData.filter(function (el) {
                            return el.ProductId == ProductId;
                        });

                        this.utilityFilterData = newArray;

                        const electriUtilityArray = utilityData.filter(function (el) {
                            return el.ProductId == 1;
                        });
                        this.ElectricUtilityData = electriUtilityArray;

                        const gasUtilityArray = utilityData.filter(function (el) {
                            return el.ProductId == 2;
                        });
                        this.GasUtilityData = gasUtilityArray;
                    }
                })
                .catch((err) => {
                    console.log(err.message);
                });
        },
        // Get Utility data  Api  end here

        // Get Utility data filter based on Product Id  start here
        getAndSetUtilityProduct(event) {
            $("#state").val(0);
            $(".rate-select").html('<option  value="0" selected="">Rate Class</option>');
            $("#zone-section").hide();
            $("#demand-section").hide();
            $("#plan-zone-section").hide();
            $(".verify-zone-section").hide();
            $(".accountOrEsid").text("Account Number");
            $(".added-account").remove();
            $("#account-no").val("2");

            if (event.target.value == "2") {
                $(".rate-section").hide();
                $(".remove-button").css("margin-top", "56px");
                $(".rate-select").html('<option  value="0" selected="">Rate Class</option>');

                $("#customer-rate-label").text("Customer Rate (¢/CCF)");
                $("#margin-rate-label").text("Margin (per CCF)");
                $(".annual-usage-lebel").text("Annual Usage (in CCF)");
                $(".plan-annual-usage-lebel").text("Annual Usage (ccf)");
                $(".plan-customer-rate-label").text("Customer Rate (ccf)");
                $(".box-rate").text("ccf");
            } else {
                $(".rate-section").show();
                $(".rate-select").html('<option  value="0" selected="">Rate Class</option>');
                $("#customer-rate-label").text("Customer Rate (¢/kwh)");
                $("#margin-rate-label").text("Margin");
                $(".annual-usage-lebel").text("Annual Usage");
                $(".plan-annual-usage-lebel").text("Annual Usage (kwh)");
                $(".plan-customer-rate-label").text("Customer Rate (kwh)");
                $(".box-rate").text("kwh");
            }

            this.ProductId = event.target.value;

            const utilityData = this.utilityData;
            const newArray = utilityData.filter(function (el) {
                return el.ProductId == event.target.value;
            });
            this.utilityFilterData = newArray;
        },
        // Get Utility data filter based on Product Id  end here

        // Get demand  data ,utility data filter based on State Id  start here
        getAndSetUtilityState(event) {
            this.demandData = [];
            $("#zone-section").hide();
            $("#demand-section").hide();
            $("#plan-zone-section").hide();
            $(".verify-zone-section").hide();

            const id = event.target.value;

            if (id === "43") {
                $("#zone-section").show();
                $("#plan-zone-section").show();
                $(".verify-zone-section").show();
                this.accountOrEsid = "2";
            }
            else if (id === "32") {
                $("#zone-section").show();
                $("#plan-zone-section").show();
                $(".verify-zone-section").show();
                this.accountOrEsid = "1";
            } else {
                $("#zone-section").hide();
                $("#plan-zone-section").hide();
                $(".verify-zone-section").hide();
                $("#zone").val("0");
                this.accountOrEsid = "1";
            }
            const ProductId = this.ProductId;
            const utilityProductData = this.utilityData;
            // Allowing Demand  on only Electric Product Start
            if (ProductId == "1") {
                axios({
                    method: "post",
                    url: constant.apiUrl + "/getDemand",
                    headers: {
                        "Content-Type": "application/json",
                        "x-access-token": localStorage.getItem("token"),
                    },
                    data: {
                        StateId: id,
                    },
                })
                    .then((result) => {
                        if (result.status === 200) {
                            if (result.data.data.length > 0) {
                                this.demandData = result.data.data;
                                $("#demand-section").show();
                                $(".accountOrEsid").text("ESID");
                                $("#AccountNumber").attr("placeholder", "Enter ESID");

                            } else {
                                this.demandData = [];
                                $("#demand-section").hide();

                                $(".accountOrEsid").text("Account Number");

                                $("#AccountNumber").attr("placeholder", "Enter Account Number");

                            }
                        }
                    })
                    .catch((err) => {
                        console.log(err.message);
                    });
            }
            // Allowing Demand  on only Electric Product End

            if (id == "0") {
                var newArray = utilityProductData.filter(function (el) {
                    return el.ProductId == ProductId;
                });
            } else {
                var newArray = utilityProductData.filter(function (el) {
                    return el.StateId == id && el.ProductId == ProductId;
                });
            }
            this.utilityFilterData = newArray;
        },
        // Get demand  data ,utility data  end here

        // Get zone data ,rate class data ,volumn data start here
        getAndSetZone(event) {
            const id = event.target.value;
            this.utilityIdPriceQuoteAdd = id;
            axios({
                method: "post",
                url: constant.apiUrl + "/UtilityZone",
                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": localStorage.getItem("token"),
                },
                data: {
                    utilityId: id,
                },
            })
                .then((result) => {
                    if (result.status === 200) {
                        if (result.data.utilityZoneData.length > 0) {
                            this.zoneData = result.data.utilityZoneData;
                        } else {
                            this.zoneData = [];
                        }

                        if (result.data.volumnData.length > 0) {
                            this.volumnData = result.data.volumnData.length;

                            $("#customer-rate-label").text("Customer Rate (¢/MCF)");
                            $("#margin-rate-label").text("Margin (per MCF)");
                            $(".annual-usage-lebel").text("Annual Usage (in MCF)");

                            $('.plan-annual-usage-lebel').text("Annual Usage (in MCF)");
                            $('.plan-customer-rate-label').text("Customer Rate (¢/MCF)");
                            $(".plan-margin-lebel").text("Margin (per MCF)");
                            $(".box-rate").text('mcf');
                        } else {
                            if (this.ProductId == "2") {
                                $("#customer-rate-label").text("Customer Rate (¢/CCF)");
                                $("#margin-rate-label").text("Margin (per CCF)");
                                $(".annual-usage-lebel").text("Annual Usage");

                                $('.plan-annual-usage-lebel').text("Annual Usage (per CCF)");
                                $('.plan-customer-rate-label').text("Customer Rate (¢/CCF)");
                                $(".plan-margin-lebel").text("Margin (per CCF)");
                                $(".box-rate").text('ccf')
                            } else {
                                $("#customer-rate-label").text("Customer Rate (¢/kwh)");
                                $("#margin-rate-label").text("Margin");
                                $(".annual-usage-lebel").text("Annual Usage");
                                $(".plan-annual-usage-lebel").text("Annual Usage (kwh)");
                                $(".plan-customer-rate-label").text("Customer Rate (kwh)");
                                $(".plan-margin-lebel").text("Margin");
                                $(".box-rate").text("kwh");

                            }
                        }

                        if (result.data.rateCodeData.length > 0) {
                            this.rateCodeData = result.data.rateCodeData;
                            var selectValues = result.data.rateCodeData;

                            var $mySelect = $(".rate-select");

                            $mySelect.html('<option value="0" selected="">Rate Class</option>');

                            $.each(selectValues, function (key, value) {
                                var $option = $("<option/>", {
                                    value: value.RateCodeId,
                                    text: value.RateCode,
                                });
                                $mySelect.append($option);
                            });
                        } else {
                            $(".rate-select").html(
                                '<option  value="0" selected="">Rate Class</option>'
                            );

                            this.rateCodeData = [];

                        }
                        // Utility Account Validation start here
                        this.utilityAccountValidation = result.data.utilityAccountData;
                        // Utility Account Validation end here
                    }
                })
                .catch((err) => {
                    console.log(err.message);
                });
        },
        // Get zone data ,rate class data ,volumn data end here

        // Add account start here
        addAccount() {
            var rateclassOption = $("#rate-select").html();
            const productId = this.ProductId;

            if (productId == "2") {
                var annualLebelText = "Annual Usage (in CCF)";
            } else {
                var annualLebelText = "Annual Usage";
            }
            const volumnData = this.volumnData;

            if (volumnData > 0) {
                var annualLebelText = "Annual Usage (in MCF)";
            } else {
                var annualLebelText = "Annual Usage";
            }

            const accountno = $("#account-no").val();
            const html =
                `
          <div class="row rate added-account" id="added-account">
          <div class="aact" >Account ` +
                accountno +
                `</div>
                    <div class="form-group col-md-6 rate-section">
                      <label>Rate Class</label>
                      <select class="form-control rate-select" id="rate-` +
                accountno +
                `">
                        ` +
                rateclassOption +
                `

                      </select>
                    </div>
                    <div class="form-group col-md-6">
                      <label class="annual-usage-lebel">` +
                annualLebelText +
                `</label>
                      <input type="text" class="form-control number-decimal" placeholder="0" id="annual-usage-` +
                accountno +
                `" />
                    </div>
                    <div class="form-group col-md-6">
                      <button id="account-remove" class="remove-button" type="button" style="font-size: 10px;padding: 4px 10px; width: fit-content;background-color: #d91717;margin-left: -2px;">Remove</button>
                    </div>
            </div></div>`;

            $(".parent-row-rate").append(html);
            $("#account-no").val(parseInt(accountno) + parseInt(1));

            if (productId == "2") {
                $(".rate-section").hide();
                $(".remove-button").css("margin-top", "56px");
            } else {
                $(".rate-section").show();
            }
        },
        // Add account end here

        // Zip Code Lookup start here
        lookUpSubmit() {
            const zipcode = this.$refs["ZipCode"].value;

            if (!this.$refs["ZipCode"].value) {
                this.errors.push({
                    ZipCode: "ZipCode is required"
                });
                return false;
            }

            axios({
                method: "post",
                url: constant.apiUrl + "/lookUpZone",
                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": localStorage.getItem("token"),
                },
                data: {
                    ZipCode: this.$refs["ZipCode"].value,
                },
            })
                .then((result) => {
                    if (result.status === 200) {
                        if (result.data.data.length > 0) {
                            console.log(JSON.stringify(result.data.data))
                            const zones = result.data.data.map(function (item) {
                                return item.Zone
                            })
                            const zips = result.data.data.find(function (item) {
                                if (item.Zip != null || item.Zip != "" || item.Zip != undefined) {
                                    return item.Zip
                                }
                            })
                            console.log(JSON.stringify(zips))
                            $("#zone").val(result.data.data[0].id);
                            if (zones.length > 1) {
                                if (zips == undefined) {
                                    alert(
                                        zipcode +
                                        " is in Zones " +
                                        zones.join(", ")
                                    );
                                }
                                else {
                                    alert(
                                        zipcode +
                                        " is in Zones " +
                                        zones.join(", ") +
                                        '\n\n\nWARNING: Zone Conflict found, please cross check the zone and zip code using the appropriate supplier matrix after a price is selected.'
                                    );
                                }
                            }
                            else {
                                if (zips == undefined) {
                                    alert(
                                        zipcode +
                                        " is in Zone " +
                                        zones
                                    );
                                }
                                else {
                                    alert(
                                        zipcode +
                                        " is in Zone " +
                                        zones +
                                        '\n\n\nWARNING: Zone Conflict found, please cross check the zone and zip code using the appropriate supplier matrix after a price is selected.'
                                    );
                                }
                            }
                            return false;
                        } else {
                            $("#zone").val(0);
                            alert("No zone found for Zip Code " + zipcode);
                            return false;
                        }
                    }
                })
                .catch((err) => {
                    console.log(err.message);
                });
        },
        // Zip Code Lookup end here

        addShowModal() {
            this.errors = [];
            const ProductId = this.ProductId;
            const utilityProductData = this.utilityData;
            $("#addSameBilling").prop('checked', false);
            // Allowing Account/ESId TEXT FOR DUKE ENERGT & DUKE GAS START
            if ($("#utility").val() === "14" || $("#utility").val() === "48") {
                $('#AddpopupEsid').get(0).type = 'text';
            } else {
                $('#AddpopupEsid').get(0).type = 'number';
            }
            // Allowing Account/ESId TEXT FOR DUKE ENERGT & DUKE GAS END

            $("#AddpopupState").html('');

            $(".addAccountOrEsid").text("Account Number");

            // $("#AcocountAdd-form").trigger("reset");
            $("#AddAccountRateClassAnnualUsagemodel").modal("show");
            var $rateClassMySelect = $("#AddpopupRateClass");
            const selectValues = this.rateCodeData;

            $rateClassMySelect.html('<option  value="0">Rate Class</option>');
            $.each(selectValues, function (key, value) {
                var $option = $("<option/>", {
                    value: value.RateCodeId,
                    text: value.RateCode,
                });

                $rateClassMySelect.append($option);
            });

            var $stateMySelect = $("#AddpopupState");
            const stateSelectValues = this.allStateData;

            $.each(stateSelectValues, function (key, value) {
                var $stateOption = $("<option/>", {
                    value: value.id,
                    text: value.StateCode,
                });

                $stateMySelect.append($stateOption);
            });
        },
        updateRate() {
            const contractRate = Number($("#visible-rate").val())
            const transferRate = Number($("#transfer-rate").val())
            $("#hidden-Rate").val(contractRate)
            $("#contract-transfer-margin").val(contractRate - transferRate)
        },
        updateMargin() {
            const newMargin = Number($("#contract-transfer-margin").val())
            const transferRate = Number($("#transfer-rate").val())
            $("#hidden-Rate").val(newMargin + transferRate);
            $("#visible-rate").val(newMargin + transferRate);
        },
        showModal(sno) {
            $("#popupState").html("");
            $("#editSameBilling").prop('checked', false);
            // Allowing Account/ESId TEXT FOR DUKE ENERGT & DUKE GAS START
            if ($("#utility").val() === "14" || $("#utility").val() === "48") {
                $('#popupEsid').get(0).type = 'text';
            } else {
                $('#popupEsid').get(0).type = 'number';
            }
            // Allowing Account/ESId TEXT FOR DUKE ENERGT & DUKE GAS END

            this.errors = [];
            const index = parseInt(sno) - 1;
            const data = this.allAccountRateClassAnnualUsageData;

            if (data[index].statevalue == "43") {
                $(".editAccountOrEsid").text("ESID");
            } else {
                $(".editAccountOrEsid").text("Account Number");
            }

            $("#AccountRateClassAnnualUsagemodel").modal("show");
            $("#popupUsage").val(data[index].annualusage);
            $("#popupEsid").val(data[index].esid);
            $("#hidden-index").val(sno);

            var $rateClassMySelect = $("#popupRateClass");
            const selectValues = this.rateCodeData;

            $rateClassMySelect.html('<option  value="0">Rate Class</option>');
            $.each(selectValues, function (key, value) {
                if (value.RateCodeId == data[index].ratevalue) {
                    var $option = $(
                        `<option selected='' value=${value.RateCodeId}>${value.RateCode}</option>`
                    );
                } else {
                    var $option = $("<option/>", {
                        value: value.RateCodeId,
                        text: value.RateCode,
                    });
                }

                $rateClassMySelect.append($option);
            });

            var $stateMySelect = $("#popupState");
            const stateSelectValues = this.allStateData;

            $.each(stateSelectValues, function (key, value) {
                if (value.id == data[index].statevalue) {
                    var $stateOption = $(
                        `<option selected='' value=${value.id}>${value.StateCode}</option>`
                    );
                } else {
                    var $stateOption = $("<option/>", {
                        value: value.id,
                        text: value.StateCode,
                    });
                }

                $stateMySelect.append($stateOption);
            });
            $("#popupCity").val(data[index].city);
            $("#popupZip").val(data[index].zip);
            $("#popupAddress").val(data[index].address);
        },
        closeModal(e) {
            e.preventDefault();
            $("#AccountRateClassAnnualUsagemodel").modal("hide");
            $("#AddAccountRateClassAnnualUsagemodel").modal("hide");
            $("#AddBillCopyModel").modal("hide");
            $("#confirmModel").modal("hide");
            $("#removeconfirmModel").modal("hide");
            $("#submit-contract").text("Submit Opportunity").prop("disabled", false);
            this.existingAccountOptions = this.existingAccountOptions.slice(0, -1);
            this.existingContactOptions = this.existingContactOptions.slice(0, -1);
        },

        closeAllModal(e) {
            $("#NintextDocGenPackages").modal("hide");
            $("#submit-contract-salesforce").text("Submit Opportunity").prop("disabled", false);
            $("#NintexDocGenPackage").html('<option value=" " dataDeliveryOptionId=" " selected>NONE</option>')
            this.existingAccountOptions = this.existingAccountOptions.slice(0, -1);
            this.existingContactOptions = this.existingContactOptions.slice(0, -1);
            $("#ExistingAccountsModel").modal("hide");
            $("#ExistingContactsModel").modal("hide");


        },

        // Edit Acount Service Address Start
        editAccountSubmit(e) {
            console.log("Started edit account submit")
            let currentUsage = this.$refs["popupUsage"].value;
            console.log("Current Usage: " + currentUsage)
            e.preventDefault();
            this.errors = [];
            const productId = this.ProductId;
            $("#AcocountAdd-form").trigger("reset");
            this.successMessageAcocount = null;
            this.serviceAddressAlert = null;
            $(".service-account_error").remove("");

            if (this.$refs["popupRateClass"].value == "0" && productId == "1") {
                this.errors.push({
                    popupRateClass: "Rate Class is required"
                });
                return false;
            }

            //if (!this.$refs["popupUsage"].value) {
            //    this.errors.push({
            //        popupUsage: "Annual Usage is required"
            //    });
            //    return false;
            //}
            // Utility Account Validation start here
            let popupEsid = this.$refs["popupEsid"].value;

            let accountLebel = "Account Number";
            let productLength = "22";

            if (this.editAccountOrEsid == "2") {
                accountLebel = "ESID";
                productLength = "17";
            } else {
                accountLebel = "Account Number";
                productLength = "22";
            }

            if (this.utilityAccountValidation.length > 0) {

                const accountLength = this.utilityAccountValidation[0].accountLength;

                if (!this.$refs["popupEsid"].value) {
                    this.errors.push({
                        popupEsid: `${accountLebel} is required`
                    });
                    return false;
                }

                if (this.$refs["popupEsid"].value.length !== accountLength) {
                    this.errors.push({
                        popupEsid: `${accountLebel} length must be ${accountLength} digits`
                    });
                    return false;
                }

                let x = this.utilityAccountValidation[0].accountUniqueValue;

                switch (x) {
                    case 1:
                        if (this.$refs["popupEsid"].value.slice(0, 4) !== "0014" && this.$refs["popupEsid"].value.slice(0, 4) !== "0004") {
                            console.log('no');
                            this.errors.push({
                                popupEsid: `${accountLebel} must start with 0014 or 0004`
                            });
                            return false;
                        }
                        break;
                    case 2:
                        if (this.$refs["popupEsid"].value.slice(0, 4) !== "0350") {
                            console.log('no');
                            this.errors.push({
                                popupEsid: `${accountLebel} must start with 0350`
                            });
                            return false;
                        }
                        break;
                    case 3:
                        if (this.$refs["popupEsid"].value.slice(0, 3) !== "080") {
                            console.log('no');
                            this.errors.push({
                                popupEsid: `${accountLebel} must start with 080`
                            });
                            return false;
                        }
                        break;
                    case 4:
                        if (this.$refs["popupEsid"].value.slice(0, 1) !== "N") {
                            console.log('no');
                            this.errors.push({
                                popupEsid: `${accountLebel} must start with N`
                            });
                            return false;
                        }
                        break;
                    case 5:
                        if (this.$refs["popupEsid"].value.slice(0, 2) !== "R0") {
                            console.log('no');
                            this.errors.push({
                                popupEsid: `${accountLebel} must start with R0`
                            });
                            return false;
                        }
                        break;
                    case 6:
                        popupEsid = popupEsid.slice(0, -1)
                        break;
                    case 7:
                        popupEsid = popupEsid.slice(0, -1)
                        popupEsid = popupEsid.slice(2);
                        console.log('case drop', popupEsid)
                        break;
                }

            } else {
                if (!this.$refs["popupEsid"].value) {
                    this.errors.push({
                        popupEsid: `${accountLebel} is required`
                    });
                    return false;
                }

                if (this.$refs["popupEsid"].value.length > productLength) {
                    this.errors.push({
                        popupEsid: `${accountLebel} maximum length can not  be more than  ${productLength} digits`,
                    });
                    return false;
                }
            }

            // Utility Account Validation end here

            if (!this.$refs["popupAddress"].value) {
                this.errors.push({
                    popupAddress: "Address is required"
                });
                return false;
            }

            if (!this.$refs["popupCity"].value) {
                this.errors.push({
                    popupCity: "City is required"
                });
                return false;
            }

            if (!this.$refs["popupZip"].value) {
                this.errors.push({
                    popupZip: "Zip is required"
                });
                return false;
            }

            const sno = parseInt($("#hidden-index").val());
            const popupRateClassValue = this.$refs["popupRateClass"].value;
            const popupRateClassText = $("#popupRateClass option:selected").text();
            let popupUsage = 0;
            if (this.$refs["popupUsage"].value) {
                popupUsage = parseInt(this.$refs["popupUsage"].value);
            }
            const popupAddress = this.$refs["popupAddress"].value;
            const popupCity = this.$refs["popupCity"].value;
            const popupZip = this.$refs["popupZip"].value;
            const popupStateValue = this.$refs["popupState"].value;
            const popupStateText = $("#popupState option:selected").text();

            const oldData = this.allAccountRateClassAnnualUsageData;
            let oldUsage = this.totalAnualUsageService;
            console.log("oldUsage: " + oldUsage)
            const updateData = oldData.map((obj) =>
                obj.sno === sno ?
                    {
                        ...obj,
                        ratevalue: popupRateClassValue,
                        ratetext: popupRateClassText,
                        annualusage: popupUsage,
                        statetext: popupStateText,
                        statevalue: popupStateValue,
                        city: popupCity,
                        address: popupAddress,
                        zip: popupZip,
                        esid: popupEsid,
                    } :
                    obj
            );

            this.allAccountRateClassAnnualUsageData = updateData;

            this.totalAnualUsageService = updateData.reduce(
                (a, {
                    annualusage
                }) => a + annualusage,
                0
            );
            var commodityString = productId == 1 ? "Electric" : "Gas"
            axios({
                method: "post",
                url: constant.apiUrl + "/sfMeterNumberLookup",
                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": localStorage.getItem("token"),
                },
                data: {
                    accountNo: popupEsid,
                    commodity: commodityString
                },
            })
                .then((result) => {
                    if (result.status === 200) {
                        if (result.data.success === true) {
                            alert("This account may already belong to an existing deal, please contact your channel manager before proceeding")
                        } else if (result.data.success === false) {
                            this.successMessageAcocount = "Data updated Successfully";
                            setTimeout(() => {
                                this.successMessageAcocount = null;
                                $("#AccountRateClassAnnualUsagemodel").modal("hide");
                            }, 3000);
                        }
                    } else {
                        alert("something went wrong");
                    }
                })
                .catch((err) => {
                    console.log(err.message);
                    alert("something went wrong");
                });

            console.log("Old Data: " + oldData.annualusage);
            console.log("New Data: " + updateData.annualusage);
            if (oldUsage != this.totalAnualUsageService) {
                this.serviceAddressAlert =
                    "Adding new Service Address(s) or modifying usages may change pricing. Run quote again to get the most accurate price";
            }
        },
        // Edit Acount Service Address End

        // Add Acount Service Address Start
        addAccountSubmit(e) {
            e.preventDefault();
            this.errors = [];
            const productId = this.ProductId;
            this.successMessageAcocountAdd = null;
            this.serviceAddressAlert = null;
            $(".service-account_error").remove("");

            if (this.$refs["AddpopupRateClass"].value == "0" && productId == "1") {
                this.errors.push({
                    popupRateClass: "Rate Class is required"
                });
                return false;
            }
            //if (!this.$refs["AddpopupUsage"].value) {
            //    this.errors.push({
            //        popupUsage: "Annual Usage is required"
            //    });
            //    return false;
            //}

            // Utility Account Validation start here
            let popupEsid = this.$refs["AddpopupEsid"].value;
            let accountLebel = "Account Number";
            let productLength = "22";

            if (this.addAccountOrEsid == "2") {
                accountLebel = "ESID";
                productLength = "17";
            } else {
                accountLebel = "Account Number";
                productLength = "22";
            }

            if (this.utilityAccountValidation.length > 0) {
                const accountLength = this.utilityAccountValidation[0].accountLength;

                if (!this.$refs["AddpopupEsid"].value) {
                    this.errors.push({
                        popupEsid: `${accountLebel} is required`
                    });
                    return false;
                }

                if (this.$refs["AddpopupEsid"].value.length !== accountLength) {
                    this.errors.push({
                        popupEsid: `${accountLebel} length must be ${accountLength} digits`
                    });
                    return false;
                }

                let x = this.utilityAccountValidation[0].accountUniqueValue;

                switch (x) {
                    case 1:
                        if (this.$refs["AddpopupEsid"].value.slice(0, 4) !== "0014" && this.$refs["AddpopupEsid"].value.slice(0, 4) !== "0004") {
                            console.log('no');
                            this.errors.push({
                                popupEsid: `${accountLebel} must start with 0014 or 0004`
                            });
                            return false;
                        }
                        break;
                    case 2:
                        if (this.$refs["AddpopupEsid"].value.slice(0, 4) !== "0350") {
                            console.log('no');
                            this.errors.push({
                                popupEsid: `${accountLebel} must start with 0350`
                            });
                            return false;
                        }
                        break;
                    case 3:
                        if (this.$refs["AddpopupEsid"].value.slice(0, 3) !== "080") {
                            console.log('no');
                            this.errors.push({
                                popupEsid: `${accountLebel} must start with 080`
                            });
                            return false;
                        }
                        break;
                    case 4:
                        if (this.$refs["AddpopupEsid"].value.slice(0, 1) !== "N") {
                            console.log('no');
                            this.errors.push({
                                popupEsid: `${accountLebel} must start with N`
                            });
                            return false;
                        }
                        break;
                    case 5:
                        if (this.$refs["AddpopupEsid"].value.slice(0, 2) !== "R0") {
                            console.log('no');
                            this.errors.push({
                                popupEsid: `${accountLebel} must start with R0`
                            });
                            return false;
                        }
                        break;
                    case 6:
                        popupEsid = popupEsid.slice(0, -1)
                        break;
                    case 7:
                        popupEsid = popupEsid.slice(0, -1)
                        popupEsid = popupEsid.slice(2);
                        console.log('case drop', popupEsid)
                        break;
                }

            } else {
                if (!this.$refs["AddpopupEsid"].value) {
                    this.errors.push({
                        popupEsid: `${accountLebel} is required`
                    });
                    return false;
                }

                if (this.$refs["AddpopupEsid"].value.length > productLength) {
                    this.errors.push({
                        popupEsid: `${accountLebel} maximum length can not  be more than  ${productLength} digits`,
                    });
                    return false;
                }
            }
            // Utility Account Validation end here

            if (!this.$refs["AddpopupAddress"].value) {
                this.errors.push({
                    popupAddress: "Address is required"
                });
                return false;
            }

            if (!this.$refs["AddpopupCity"].value) {
                this.errors.push({
                    popupCity: "City is required"
                });
                return false;
            }

            if (!this.$refs["AddpopupZip"].value) {
                this.errors.push({
                    popupZip: "Zip is required"
                });
                return false;
            }

            const popupRateClassValue = this.$refs["AddpopupRateClass"].value;
            const popupRateClassText = $("#AddpopupRateClass option:selected").text();
            let popupUsage = 0;
            if (this.$refs["AddpopupUsage"].value) {
                popupUsage = parseInt(this.$refs["AddpopupUsage"].value);
            }
            const popupAddress = this.$refs["AddpopupAddress"].value;
            const popupCity = this.$refs["AddpopupCity"].value;
            const popupZip = this.$refs["AddpopupZip"].value;
            const popupStateValue = this.$refs["AddpopupState"].value;
            const popupStateText = $("#AddpopupState option:selected").text();
            const oldData = this.allAccountRateClassAnnualUsageData;

            var last_element = oldData[oldData.length - 1];
            const sno = parseInt(last_element.sno) + 1;

            var params = {};
            params["annualusage"] = popupUsage;
            params["ratetext"] = popupRateClassText;
            params["ratevalue"] = popupRateClassValue;
            params["sno"] = sno;
            params["statetext"] = popupStateText;
            params["statevalue"] = popupStateValue;
            params["city"] = popupCity;
            params["zip"] = popupZip;
            params["address"] = popupAddress;
            params["esid"] = popupEsid;
            params["billcopyimage"] = "";

            oldData.push(params);

            this.allAccountRateClassAnnualUsageData = oldData;

            const updateddata = this.allAccountRateClassAnnualUsageData;

            this.totalAnualUsageService = updateddata.reduce(
                (a, {
                    annualusage
                }) => a + annualusage,
                0
            );

            var commodityString = productId == 1 ? "Electric" : "Gas"
            axios({
                method: "post",
                url: constant.apiUrl + "/sfMeterNumberLookup",
                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": localStorage.getItem("token"),
                },
                data: {
                    accountNo: popupEsid,
                    commodity: commodityString
                },
            })
                .then((result) => {
                    if (result.status === 200) {
                        if (result.data.success === true) {
                            alert("This account may already belong to an existing deal, please contact your channel manager before proceeding")
                        } else if (result.data.success === false) {
                            this.successMessageAcocountAdd = "Data Added Successfully";
                            setTimeout(() => {
                                this.successMessageAcocountAdd = null;
                                $("#AddAccountRateClassAnnualUsagemodel").modal("hide");
                                $("#AcocountAdd-form").trigger("reset");
                            }, 3000);
                        }
                    } else {
                        alert("something went wrong");
                    }
                })
                .catch((err) => {
                    console.log(err.message);
                    alert("something went wrong");
                });
            this.serviceAddressAlert =
                "Adding new Service Address(s) or modifying usages may change pricing. Run quote again to get the most accurate price";
        },
        // Add Acount Service Address End

        // Change Account or Esid Label on the bases on Parent State  Start
        getStateAccount(event) {
            this.accountOrEsid = "1";
            if (event.target.value == "43") {
                this.accountOrEsid = "2";
            } else {
                this.accountOrEsid = "1";
            }
        },
        // Change Account or Esid Label on the bases on Parent State  End

        // Change Account or Esid Label on the bases on State Ad Service Address Start
        getAddStateAccountInfo(event) {
            this.addAccountOrEsid = "1";
            if (event.target.value == "43") {
                $(".addAccountOrEsid").text("ESID");
                this.addAccountOrEsid = "2";
            } else {
                $(".addAccountOrEsid").text("Account Number");
                this.addAccountOrEsid = "1";
            }
        },
        // Change Account or Esid Label on the bases on State Add Service Address End

        // Change Account or Esid Label on the bases on State Ad Service Address Start
        getEditStateAccountInfo(event) {
            this.editAccountOrEsid = "1";
            if (event.target.value == "43") {
                $(".editAccountOrEsid").text("ESID");
                this.editAccountOrEsid = "2";
            } else {
                $(".editAccountOrEsid").text("Account Number");
                this.editAccountOrEsid = "1";
            }
        },
        // Change Account or Esid Label on the bases on State Add Service Address End

        // Add Bill  Copy  Section Start
        billCopySubmit(sno) {
            this.errors = [];

            if (!this.$refs["CompanyName"].value) {
                this.errors.push({
                    CompanyName: "Business Name is required"
                });
                $("html, body").animate({
                    scrollTop: $("#CompanyName").offset().top,
                },
                    500
                );
                return false;
            }

            if (!this.$refs["ContactFirstName"].value) {
                this.errors.push({
                    ContactFirstName: "First Name is required"
                });
                $("html, body").animate({
                    scrollTop: $("#ContactFirstName").offset().top,
                },
                    500
                );
                return false;
            }

            if (!this.$refs["ContactLastName"].value) {
                this.errors.push({
                    ContactLastName: "Last Name is required"
                });
                $("html, body").animate({
                    scrollTop: $("#ContactLastName").offset().top,
                },
                    500
                );
                return false;
            }
            if (!this.$refs["JobTitle"].value) {
                this.errors.push({
                    JobTitle: "Job Title is required"
                });
                $("html, body").animate({
                    scrollTop: $("#JobTitle").offset().top,
                },
                    500
                );
                return false;
            }

            if (!this.$refs["Phone"].value) {
                this.errors.push({
                    Phone: "Phone is required"
                });
                $("html, body").animate({
                    scrollTop: $("#Phone").offset().top,
                },
                    500
                );
                return false;
            }
            if (this.$refs["Phone"].value.length != 10) {
                this.errors.push({
                    Phone: "Phone should be only 10 digits",
                });
                $("html, body").animate({
                    scrollTop: $("#Phone").offset().top,
                },
                    500
                );
                return false;
            }

            if (!this.$refs["Email"].value) {
                this.errors.push({
                    Email: "Email is required"
                });
                $("html, body").animate({
                    scrollTop: $("#Email").offset().top,
                },
                    500
                );
                return false;
            }

            const pattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
            if (!pattern.test(this.$refs["Email"].value)) {
                this.errors.push({
                    Email: "Email must be valid format"
                });
                $("html, body").animate({
                    scrollTop: $("#Email").offset().top,
                },
                    500
                );
                return false;
            }

            if (!this.$refs["cnfEmail"].value) {
                this.errors.push({
                    cnfEmail: "Confirm Email is required"
                });
                $("html, body").animate({
                    scrollTop: $("#cnfEmail").offset().top,
                },
                    500
                );
                return false;
            }

            if (this.$refs["cnfEmail"].value !== this.$refs["Email"].value) {
                this.errors.push({
                    cnfEmail: "Email & Confirm Email should be same"
                });
                $("html, body").animate({
                    scrollTop: $("#cnfEmail").offset().top,
                },
                    500
                );
                return false;
            }

            if (!this.$refs["Street"].value) {
                this.errors.push({
                    Street: "Street is required"
                });
                $("html, body").animate({
                    scrollTop: $("#Street").offset().top,
                },
                    500
                );
                return false;
            }

            if (!this.$refs["City"].value) {
                this.errors.push({
                    City: "City is required"
                });
                $("html, body").animate({
                    scrollTop: $("#City").offset().top,
                },
                    500
                );
                return false;
            }

            if (!this.$refs["Zip"].value) {
                this.errors.push({
                    Zip: "Zip is required"
                });
                $("html, body").animate({
                    scrollTop: $("#Zip").offset().top,
                },
                    500
                );
                return false;
            }

            const index = sno - 1;

            const imageStore = this.allAccountRateClassAnnualUsageData[index].billcopyimage;
            if (imageStore === "") {
                $("#billCopyForm").trigger("reset");
                $(".upload-img").prop("disabled", false);
                $(".remove-img").hide();
                $(".image-box").attr("src", "");
            } else {
                $(".upload-img").prop("disabled", true);
                $(".remove-img").show();
                $(".image-box").attr("src", imageStore);
            }

            this.selectedFile = "";
            $("#AddBillCopyModel").modal("show");
            $("#BillSnoHidden").val(sno);
        },
        handleChange(event) {
            $("#imageFileError").html("");
            this.selectedFile = event.target.files[0];

            const fileExtension = this.selectedFile.name.split(".")[1];

            if ($.inArray(fileExtension, ["gif", "png", "jpg", "jpeg"]) == -1) {
                this.errors.push({
                    file: "Only Image file  is allowed",
                });
                return false;
            }
        },
        formbillCopySubmit(e) {
            console.log("Starting form bill copy submit")
            e.preventDefault();
            const sno = parseInt($("#BillSnoHidden").val());
            if (this.selectedFile) {
                this.blah = URL.createObjectURL(this.selectedFile);
                $(".image-box").attr("src", this.blah);
                $(".upload-img").prop("disabled", true);
                $(".remove-img").show();

                const image = this.selectedFile;
                const oldData = this.allAccountRateClassAnnualUsageData;

                var bodyFormData = new FormData();
                bodyFormData.append("file", this.selectedFile);

                axios({
                    method: "post",
                    url: constant.apiUrl + "/uploadBillCopyImage",
                    headers: {
                        "Content-Type": "application/json",
                        "x-access-token": localStorage.getItem("token"),
                    },

                    data: bodyFormData,
                })
                    .then((result) => {
                        if (result.status === 200) {

                            const updateData = oldData.map((obj) =>
                                obj.sno === sno ?
                                    {
                                        ...obj,
                                        billcopyimage: result.data.data,
                                    } :
                                    obj
                            );
                            this.allAccountRateClassAnnualUsageData = updateData;

                            localStorage.removeItem("serviceAddressData");

                            localStorage.setItem(
                                "serviceAddressData",
                                JSON.stringify(this.allAccountRateClassAnnualUsageData)
                            );

                            const retrievedserviceAddressData = JSON.parse(
                                localStorage.getItem("serviceAddressData")
                            );

                            this.successMessageBillCopyAdd = "Bill Copy Image Uploaded Successfully";
                            setTimeout(() => {
                                this.successMessageBillCopyAdd = null;
                                $("#AddBillCopyModel").modal("hide");
                            }, 3000);
                        } else {
                            alert("Something went wrong");
                        }
                    })
                    .catch((err) => {
                        console.log(err.mesage);
                    });
            } else {
                $(".upload-img").prop("disabled", false);
                $(".remove-img").hide();
                this.errors.push({
                    file: "Image file is required",
                });
                return false;
            }
        },
        removeBillCopySubmitConfirm(e) {
            e.preventDefault();
            $("#RemoveConfirmation").modal("show");
        },
        removeBillCopySubmit(e) {
            this.successMessageBillCopyAdd = null;

            axios({
                method: "post",
                url: constant.apiUrl + "/removeBillCopyImage",
                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": localStorage.getItem("token"),
                },
                data: {
                    image: $(".image-box").attr("src"),
                    customerId: this.customerId,
                    Seq: parseInt($("#BillSnoHidden").val()),
                },
            })
                .then((result) => {
                    if (result.status === 200) {
                        const sno = parseInt($("#BillSnoHidden").val());
                        $("#billCopyForm").trigger("reset");
                        $(".upload-img").prop("disabled", false);
                        $(".remove-img").hide();
                        $(".image-box").attr("src", "");

                        const oldData = this.allAccountRateClassAnnualUsageData;

                        const updateData = oldData.map((obj) =>
                            obj.sno === sno ?
                                {
                                    ...obj,
                                    billcopyimage: "",
                                } :
                                obj
                        );
                        this.allAccountRateClassAnnualUsageData = updateData;
                        $("#RemoveConfirmation").modal("hide");

                        this.successMessageBillCopyAdd = "Bill Copy Image Removed Successfully";
                        setTimeout(() => {
                            this.successMessageBillCopyAdd = null;
                        }, 3000);
                    } else {
                        alert("Something went wrong");
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        closeConf() {
            $("#RemoveConfirmation").modal("hide");
        },
        // Add Bill  Copy Section End

        // Reset Step One Page Start
        stepOneReset() {
            location.href = "/pricequote";
        },
        // Reset Step One Page End

        // Remove Service Acccount Start
        removeServiceConfirmModel(sno) {
            $("#removeSno").val(sno);
            $("#removeconfirmModel").modal("show");
        },
        removeServiceAccount(e) {
            e.preventDefault();
            this.successMessageServiceAccount = null;
            const sno = parseInt($("#removeSno").val());
            const oldData = this.allAccountRateClassAnnualUsageData;
            const updateData = oldData.filter((item) => {
                return item.sno !== sno;
            });

            this.allAccountRateClassAnnualUsageData = updateData;
            this.successMessageServiceAccount = "Service Account Removed Successfully";
            setTimeout(() => {
                this.successMessageServiceAccount = null;
                $("#removeconfirmModel").modal("hide");
            }, 3000);
        },
        // Remove Service Acccount End
        // Same Billing Address check box Start
        addSameBilling() {
            if ($('#addSameBilling').is(":checked")) {
                $("#AddpopupAddress").val($("#Street").val())
                $("#AddpopupCity").val($("#City").val())
                $("#AddpopupState").val($("#info-StateId").val())
                $("#AddpopupZip").val($("#Zip").val())
            } else {
                $("#AddpopupAddress,#AddpopupCity,#AddpopupZip").val('')
                $("#AddpopupState").val('1')
            }
        },
        editSameBilling() {
            if ($('#editSameBilling').is(":checked")) {
                $("#popupAddress").val($("#Street").val())
                $("#popupCity").val($("#City").val())
                $("#popupState").val($("#info-StateId").val())
                $("#popupZip").val($("#Zip").val())
            } else {
                $("#popupAddress,#popupCity,#popupZip").val('')
                $("#popupState").val('1')
            }
        },
        clickTou(e) {
            e.preventDefault();
            this.showTou = !this.showTou;
        },
        // Same Billing Address check box End

    },
};
</script>


<style>
.bold {
    font-weight: bold;
}

.large {
    font-size: 20px;
}

.ui-datepicker-calendar,
#visible-rate {
    display: none;
}

#ui-datepicker-div {
    display: none;
}

.new-message {
    text-align: left !important;
}

.box-img {
    height: 70px;
    width: 100%;
    object-fit: contain;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}


.new-quote .p-q .notes-column ul {
    margin-top: 10px;
}

.new-quote .p-q .notes-column ul li {
    padding: 10px 0px;
}

.new-quote .p-q .notes-column ul li:not(:last-child) {
    border-bottom: 1px solid #ddd;
}

.sellApproved {
    color: red !important;
    padding-bottom: 0px !important;
    margin-bottom: 0px !important;
    font-size: 14px !important;
}

.sellApprovedLink {
    color: red !important;
    border: none !important;
    background-color: white !important;
    box-shadow: none !important;
    padding-top: 0px !important;
    font-weight: bold !important;
    text-decoration: underline;
    margin-top: 0px !important;
}

a:hover {
    border: none !important;
}

.rate-button {
    margin-top: 15px;
}

.importDate {
    font-size: 10px;
}

.loading-message {
    display: none;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    margin: 0 auto;
    width: 30%;
    text-align: center;
    color: #02954f;
}

.form-label {
    margin-top: 5px;
    margin-bottom: 5px !important;
}

.field-input {
    margin-bottom: 15px !important;
}

.field-input::placeholder {
    color: #dadada !important;
}

.final-check {
    margin: 20px;
    color: red !important;
    font-size: 18px !important;
}

.new-quote .steps .numb {
    width: 25%;
}

.new-quote-button {
    height: 50px;
    justify-content: center;
    width: 150px;
    padding: 10px;
    display: flex;
    margin: 0 auto;
}

.rate-group {
    margin-top: 30px;
}

.red {
    color: crimson;
}

.tooltiptext,
.tooltiptextsmall {
    display: none;
    color: black;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    background-color: rgba(200, 200, 200, 0.80);
    z-index: 1;
}

.tooltip-wrapper:hover .tooltiptext {
    display: flex;
    position: relative;
    width: 100%;
    padding: 10px;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.tooltip-wrapper:hover .tooltiptextsmall {
    width: auto;
    display: flex;
    position: relative;
    padding: 10px;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.modal-header {
    padding: 2rem 2rem 0rem;
}

.dupe-message {
    display: flex;
    text-align: center;
    padding-top: 1.5;
    padding-bottom: 1.5em;

}

.dupe-modal {
    padding: 1.5em !important;
}

.dupe-dropdown {
    cursor: pointer;
}

.dupe-title {
    font-size: 24px !important;
}

.iOS-dropdowns-wrap {
    display: flex;
    flex-direction: row;
}

.ios-dropdown {
    padding-left: 10px !important;
}

.tou-message {
    font-weight: bold !important;
    font-size: 20px !important;
}

.tou-span {
    color:#02954f;
}
</style>